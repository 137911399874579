import { underlineText } from "../utilities/document";
import { drawBox } from "./utilities/document"

export const showDefect = (doc, { Defect }, startX, startY) => {
    drawBox(doc, startX, startY, 395, 85);

    underlineText(doc, "Defect / Detail of Work Required", startX + 5, startY + 3);

    doc
        .font("Helvetica-Bold")
        .text(Defect, startX + 5, doc.y + 14)
        .font("Helvetica");
}