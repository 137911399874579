import { thunk } from "easy-peasy";
import { v4 } from "uuid";
// import qs from 'qs';

import setAuthToken from "../../utils/setAuthToken";
import { loadEmployee, listValidities, login } from "../../services/cfbsKiosk";
import { createGetEmployeePayload } from "./payloadBuilder";
import { handleAcumaticaError } from "../../utils/handleAcumaticaError";
import { qualSplit } from "../../utils/qualificationSplitter";

const employeeThunks = {
  /* #region  Get Employee */
  getEmployee: thunk(async (actions, scannedEmployee, helpers) => {
    if (localStorage.getItem("token")) {
      setAuthToken(localStorage.getItem("token"));
    }
    const query = await createGetEmployeePayload({
      scannedEmployee,
    });

    try {
      const url = helpers.getState().apiBaseURL;
      const configAndEmployee = await loadEmployee(url, query);
      const { employee, kioskConfig } = configAndEmployee.data;

      actions.setUser(employee);
      employee.kioskScanTimeLimit &&
        actions.setQueueScanLockSeconds(employee.kioskScanTimeLimit);

      localStorage.setItem("user", JSON.stringify(employee));
      let validities = null;

      if (kioskConfig && kioskConfig.CentrikBaseURL) {
        Promise.all([
          //using centrik
          listValidities(url, employee.CentrikID),
          actions.getProjectTasks({
            employeeId: employee.EmployeeID,
            fromLogin: true,
          }),
          actions.getNonRoutineOptions(),
          actions.setUsingCentrick(true),
        ])
          .then((data) => {
            validities = data[0];

            actions.setUserValidites(validities);
            actions.summarizeValities({ validities, employee });
            actions.setLoading(false);
          })
          .catch((error) => {
            actions.setAlerts({ error, type: "danger" });
          });
      } else {
        Promise.all([
          //not using centrik
          actions.getProjectTasks({
            employeeId: employee.EmployeeID,
            fromLogin: true,
          }),
          actions.getNonRoutineOptions(),
        ]).then((data) => {
          actions.setUserValidites({});
          actions.setQualificationsMechanic(true);
          actions.setQualificationsInspector({});
          actions.setQualificationsBoroscope(false);
          actions.setQualificationsFailed("");
          actions.setLoading(false);
        });
      }
    } catch (err) {
      console.log("err", err);

      const msg = handleAcumaticaError(err);
      // const eMsg = err.response.data.message
      //   ? err.response.data.message
      //   : err.message;
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  Sign Out */
  signOut: thunk((actions, _, _h) => {
    actions.setAlerts({
      error: "EXIT APPLICATION function selected",
      type: "warning",
    });
    window.location = window.location.origin;
  }),
  /* #endregion */

  /* #region  Set Alerts */
  setAlerts: thunk((actions, alert) => {
    alert.id = v4();
    actions.setAlert(alert);
    setTimeout(() => {
      actions.removeAlert(alert);
      actions.setLoading(false);
    }, 5000);
  }),
  /* #endregion */

  getLogin: thunk(async (actions, { loginInput, history }, helpers) => {
    try {
      actions.setLoading(true);
      const loginCreds = await login(helpers.getState().apiBaseURL, loginInput);
      const {
        token,
        tenant,
        logo,
        favicon,
        spinner,
        autoLogout,
        autoLogoutMinutes,
      } = loginCreds.data.data;

      autoLogout && localStorage.setItem("autoLogout", autoLogout);
      autoLogoutMinutes &&
        localStorage.setItem("autoLogoutMinutes", autoLogoutMinutes);
      actions.setLogo(logo);
      actions.setFavicon(favicon);
      actions.setLocalStorageToken(token);
      actions.setTenant(tenant);
      actions.setSpinner(spinner);
      actions.setLoading(false);
      history.push("/");
    } catch (e) {
      actions.setLoading(false);
      actions.setAlerts({
        error: `Login failed. Incorrect username and password.`,
        type: "danger",
      });
    }
  }),

  summarizeValities: thunk(async (actions, obj) => {
    const { validities, employee: user } = obj;
    const creds = validities && validities.data ? validities.data : null;
    //const user = state.user;

    let customer = [];
    let personalQualifications = [];
    let typeQualifications = [];
    let missingQualification = [];
    let warningQualification = [];
    let failedQualification = "";
    let mechanicQualified = true;
    let inspectorQualified = {};
    let boroscopeQualified = false;

    /* #region  make sure only non-expired qualifications are compared */
    // if (validities) {
    if (creds && creds.personal) {
      creds.personal.forEach((c) => {
        c.expiresOn &&
          new Date() <= new Date(c.expiresOn) &&
          personalQualifications.push(c.qualificationName);
        const expiryDate = new Date(c.expiresOn);
        const difference = expiryDate.getTime() - new Date();
        const expiresInDays = Math.ceil(difference / (1000 * 3600 * 24));

        /* #region Check for type qualifications and  against grace days */

        /* #region  look for missing and check expiry against grace days */
        if (user.qualificationGraceDays) {
          isNaN(expiresInDays) &&
            c.qualificationMandatory &&
            missingQualification.push(
              `${c.qualificationName} has an invalid expiry or missing expiry date`
            );

          !isNaN(expiresInDays) &&
            c.qualificationMandatory &&
            expiresInDays <= user.qualificationGraceDays &&
            missingQualification.push(
              `${c.qualificationName} expires in ${expiresInDays} days.`
            );
        }
        /* #endregion */

        /* #region  look expiring days on warning days if array exists */
        if (
          user.qualificationWarningDays &&
          user.qualificationWarningDays.length
        ) {
          !isNaN(expiresInDays) &&
            c.qualificationMandatory &&
            user.qualificationWarningDays.includes(expiresInDays) &&
            warningQualification.push(
              `Warning: ${c.qualificationName} expires in ${expiresInDays} days.`
            );
        }
        /* #endregion */

        typeQualifications = creds.personal.reduce((arrayResult, cred) => {
          cred.qualificationExternalId === "TYPE" &&
            arrayResult.push(qualSplit(cred.qualificationName));
          return arrayResult;
        }, []);
      });
    }

    actions.setQualificationsPersonal(personalQualifications);

    actions.setQualificationsType(typeQualifications);

    // view qualification requirements for user
    if (missingQualification.length === 0) {
      if (user.qualificationReqs) {
        const checkQualifications = (qualifications = []) => {
          return qualifications.every((qualification) => {
            if (!personalQualifications.includes(qualification)) {
              failedQualification = qualification;
              missingQualification.push(
                `Failed to verify ${qualification} qualifications`
              );
              return false;
            }
            return true;
          });
        };

        /* #region  check mechanic qualifications */
        mechanicQualified = user.qualificationReqs.mechanicQuals
          ? checkQualifications(user.qualificationReqs.mechanicQuals)
          : mechanicQualified;
        /* #endregion */

        /* #region  check inspector qualifications */
        // currently only handle 2 or less indexes in array
        user.qualificationReqs.inspectorQuals &&
          user.qualificationReqs.inspectorQuals.forEach((i) => {
            if (i[Object.keys(i)[0]].length === 1) {
              inspectorQualified[Object.keys(i)[0]] =
                personalQualifications.includes(i[Object.keys(i)[0]][0]);
            }
            if (i[Object.keys(i)[0]].length === 2) {
              inspectorQualified[Object.keys(i)[0]] = false;
              //loop through each personal qualification
              //checking for 2 values to be part of the qualification
              const keys = Object.keys(personalQualifications);
              for (let i2 = 0; i2 < keys.length; i2++) {
                if (
                  personalQualifications[keys[i2]].includes(
                    i[Object.keys(i)[0]][0]
                  ) &&
                  personalQualifications[keys[i2]].includes(
                    i[Object.keys(i)[0]][1]
                  )
                ) {
                  inspectorQualified[Object.keys(i)[0]] = true;
                  break;
                }
              }
            }
          });
        /* #endregion */

        /* #region  check boroscope qualifications */

        boroscopeQualified = user.qualificationReqs.boroscopeQuals.some(
          (qualification) => personalQualifications.includes(qualification)
        );

        /* #endregion */

        /* #region  check mecahnic and inspector qualifications */
        user.Inspector === "YES"
          ? (mechanicQualified = checkQualifications(
              user.qualificationReqs.certifyingCompetency
            ))
          : (mechanicQualified = checkQualifications(
              user.qualificationReqs.mechanicCompetency
            ));
        /* #endregion */

        /* #region  check category qualifications using mechanicQualified process */
        if (user.qualificationReqs.active && mechanicQualified) {
          user.qualificationReqs.category1 &&
            (mechanicQualified = checkQualifications(
              user.qualificationReqs.category1
            ));
          user.qualificationReqs.category2 &&
            (mechanicQualified = checkQualifications(
              user.qualificationReqs.category2
            ));
          user.qualificationReqs.category3 &&
            (mechanicQualified = checkQualifications(
              user.qualificationReqs.category3
            ));
        }

        /* #endregion */
      }
    } else {
      mechanicQualified = false;
    }

    /* #region  type specific qualifications */
    if (creds && creds.typeSpecific) {
      customer = creds.typeSpecific.slice(0);

      actions.setQualificationsCustomer(customer);

      customer.forEach((c) => {
        c.mechanicQualified = mechanicQualified;
        c.validities.every((v) => {
          const expiresOnDate = new Date(v.expiresOn);
          const difference = expiresOnDate.getTime() - new Date();
          const expiresInDays = Math.ceil(difference / (1000 * 3600 * 24));

          if (
            (isNaN(expiresInDays) ||
              expiresInDays <= user.qualificationGraceDays) &&
            v.qualificationMandatory
          ) {
            c.mechanicQualified = false;
            c.failedMessage = `${v.qualificationName} failed expiry date check`;
            return false;
            // c.missingQualification.push(
            //   `Missing qualification ${v.qualificationName}.`
            // );
          } else {
            return true;
          }

          // state.qualifications.customer[customer.number] = airlineValidity
          //  ? airlineValidity.qualificationName
          //  : [];
        });
      });
    }
    /* #endregion */

    /* #region  mechanic not qualified */
    if (!mechanicQualified) {
      missingQualification.push(`You can not continue`);
      actions.setAlertsAndLock(missingQualification);
    }

    if (mechanicQualified && warningQualification.length) {
      const alert = {
        id: v4(),
        error: warningQualification,
        type: "warning",
      };

      //state.alerts = [...state.alerts, alert];
      actions.setAlerts(alert);
    }
    /* #endregion */
    // }

    /* #region  set state for qualifications*/
    actions.setQualificationsMechanic(mechanicQualified);
    actions.setQualificationsInspector(inspectorQualified);
    actions.setQualificationsBoroscope(boroscopeQualified);
    actions.setQualificationsFailed(failedQualification);

    /* #endregion */
  }),
};

export default employeeThunks;
