import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

const SelectGeography = (props) => {
  const { tenant } = useStoreState((state) => ({
    tenant: state.tenant,
  }));

  const { setTenant, setLocalStorageToken, setLogo, setFavicon, setSpinner } =
    useStoreActions((actions) => actions);

  const setGeography = (geo) => {
    const usToken =
      /*test*/ 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InVzQGNmYnMtdXMuY29tIiwiaWF0IjoxNTk4NzMzNTQwfQ.axx8JP4L0C9hsj9eFq_gRl_LMEv_UPvFkJ_eEtZaisE';
    // /*production*/ 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VTUHJvZHVjdGlvbkBjZmJzLXVzLmNvbSIsImlhdCI6MTYzMDI4NTE3Mn0.YiPwt8H-tcy4WNBIy3PhlKbzNfqlUp0R2bPCz8-KaLo';

    if (geo === 'us') {
      setTenant(tenant);
      setLocalStorageToken(usToken);
      localStorage.setItem('autoLogout', true);
      localStorage.setItem('autoLogoutMinutes', 3);
      setFavicon('favicon_sts.ico');
      setLogo('logoModCenterTest.png');
      setSpinner('rotate_sts.gif');
      props.history.push('/');
    }

    const ukToken =
      /*test*/ 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InVrQGNmYnMtdXMuY29tIiwiaWF0IjoxNTk4NzMzNTA1fQ.-CD-fflwCiFiJWm_yMacPsfb7PWlzMpu9sjJLIZbuZE';
    // /* production */ 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VLUHJvZHVjdGlvbkBjZmJzLXVzLmNvbSIsImlhdCI6MTYzMDI4NTMxMH0.3yksEu2N3JNvj4tCRP8ac4h_zTscMNo898Rw21saql8';
    if (geo === 'uk') {
      setTenant(tenant);
      setLocalStorageToken(ukToken);
      setFavicon('favicon_sts.ico');
      setLogo('logoModCenterUKTest.png');
      setSpinner('rotate_sts.gif');

      props.history.push('/');
    }
  };

  return (
    <div className="jumbotron text-center">
      <h1 className="mb-4">Please select a geography</h1>
      <ul>
        <li className="mb-4">
          <div
            onClick={() => setGeography('us')}
            className="text-primary cursor-pointer"
          >
            <h2>US - STS Mod Center</h2>
          </div>
        </li>
        <li>
          <div
            onClick={() => setGeography('uk')}
            className="text-primary cursor-pointer"
          >
            <h2>UK - STS Aviation Services</h2>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SelectGeography;
