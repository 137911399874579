import { thunk } from "easy-peasy";
// import moment from 'moment';

import {
  listUserProjectTasks,
  listProjects,
  listProjectTasks,
  loadTask,
  removeTasksFromProject,
  listNonRoutineOptions,
  addNonRoutineTaskToProject,
  searchAndQualify,
  addInspectionRejection,
  getTaskCard,
} from "../../services/cfbsKiosk";
import { createPutInspectionRejectionPayload } from "./payloadBuilder";
import { listProjectTasksAndSetFilter } from "./helpers";
import { handleAcumaticaError } from "../../utils/handleAcumaticaError";

const projectThunks = {
  /* #region  getProjectTask */
  getProjectTask: thunk(async () => ({})),
  /* #endregion */

  /* #region  getProjectTasks */
  getProjectTasks: thunk(async (actions, param, helpers) => {
    let employeeId;
    let fromLogin = false;
    let kioskScanTimeLimit = null;

    //check for scan time limit
    if (helpers.getState().user.kioskScanTimeLimit) {
      kioskScanTimeLimit = helpers.getState().user.kioskScanTimeLimit;
    }

    //check if param is an object from login, if not param is a string
    if (typeof param === "object") {
      employeeId = param.employeeId;
      fromLogin = param.fromLogin;
    } else {
      employeeId = param;
    }

    try {
      const url = helpers.getState().apiBaseURL;
      const myProjects = await listUserProjectTasks(url, employeeId);

      //lock the queue to prevent future scans of new task cards if setup requires
      fromLogin &&
        kioskScanTimeLimit &&
        myProjects.data.length &&
        actions.setQueueLocked();

      const curProjectCount = helpers.getState().projectTasks;

      myProjects.data.length === 1 &&
        kioskScanTimeLimit &&
        !fromLogin &&
        (!curProjectCount || curProjectCount.length === 0) &&
        actions.setQueueScanCountDownIsActive(true);

      myProjects.data.length === 0 &&
        kioskScanTimeLimit &&
        !fromLogin &&
        (!curProjectCount || curProjectCount.length === 0) &&
        actions.setQueueScanCountDownIsActive(false) &&
        actions.setQueueScanLockSeconds(kioskScanTimeLimit) &&
        actions.clearQueueLocked();

      actions.setProjectTasks(myProjects.data);
      actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  searchProjects */
  searchProjects: thunk(async (actions, searchValue, helpers) => {
    try {
      const url = helpers.getState().apiBaseURL;
      const foundProjects = await listProjects(url, searchValue);
      actions.setFoundProjects(foundProjects.data);
      actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  getTask */
  getTask: thunk(async (actions, taskId, helpers) => {
    try {
      //
      actions.setLoading(true);
      const url = helpers.getState().apiBaseURL;
      const task = await loadTask(url, taskId);
      if (task.data && task.data.length) {
        actions.setPage("search");

        const foundProjects = await listProjects(url, task.data[0].AircraftCD);
        actions.setFoundProjects(foundProjects.data);

        actions.setFilteredFoundProject(task.data[0].ProjectCD);

        await listProjectTasksAndSetFilter(
          url,
          task.data[0].ProjectCD,
          actions
        );
        actions.setFilteredTasks(task.data[0].TaskCD);
      }

      actions.setLoading(false);
    } catch (err) {
      actions.setLoading(false);
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  getTaskForFilteredProject */
  getTaskForFilteredProject: thunk(async (actions, projectId, helpers) => {
    try {
      const url = helpers.getState().apiBaseURL;
      const tasks = await listProjectTasks(url, projectId);
      actions.setTasksByFilteredFoundProject(tasks.data);
      actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  addTaskToProjectTasks
  
  addTaskToProjectTasks: thunk(async (actions, taskToAdd, helpers) => {
    //const stateTasks = helpers.getState().tasksByFilteredFoundProject;
    // const taskToAdd = stateTasks.filter(
    //   (t) => t.ProjectTaskID.value === projectTaskId
    // );

    const user = helpers.getState().user;
    try {
      const newProjectTaskPayload = await createAddTaskToProjectPayload({
        ...taskToAdd,
        accountId: user.AccountID,
        employeeId: user.EmployeeID,
        employeeRefNo: user.EmployeeRefNo,
        contactId: user.ContactID,
        acmVersion: user.ACMVersion,
      });

      const url = helpers.getState().apiBaseURL;
      const myProjects = await addTaskToProject(url, newProjectTaskPayload);

      const curProjectCount = helpers.getState().projectTasks;
      if (!curProjectCount || curProjectCount.length === 0) {
        actions.setQueueScanCountDownIsActive(true);
      }
      //debugger;

      actions.setProjectTasks(myProjects.data);
      actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: 'danger' });
    }
  }),
  /* #endregion */

  /* #region  addTaskToProjectTasksByCode */
  addTaskToProjectTasksByCode: thunk(async (actions, barcode, helpers) => {
    try {
      const url = helpers.getState().apiBaseURL;
      const qualifications = helpers.getState().qualifications;
      const tenant = helpers.getState().tenant;
      const accountId = helpers.getState().user.AccountID;
      const employeeId = helpers.getState().user.EmployeeID;
      const contactId = helpers.getState().user.ContactID;
      const employeeRefNo = helpers.getState().user.EmployeeRefNo;
      const acmVersion = helpers.getState().user.ACMVersion;
      const inspector = helpers.getState().user.Inspector;
      //const kioskScanTimeLimit = helpers.getState().user.kioskScanTimeLimit;
      let projectId = null;
      const curProjects = helpers.getState().projectTasks;
      curProjects &&
        curProjects.length &&
        (projectId = curProjects[0].WorkOrderID);

      const newTask = await searchAndQualify(url, {
        barcode,
        qualifications,
        tenant,
        accountId,
        employeeId,
        employeeRefNo,
        contactId,
        acmVersion,
        projectId,
        inspector,
      });

      if (newTask.data.length === 0) {
        actions.setAlerts({
          error: "No task was found using this barcode",
          type: "warning",
        });
      } else {
        //we only get here is there is a projectTask to add to queue
        const curProjectCount = helpers.getState().projectTasks;
        const queueScanLockSeconds = helpers.getState().queueScanLockSeconds;
        if (
          (!curProjectCount || curProjectCount.length === 0) &&
          queueScanLockSeconds
        ) {
          actions.setQueueScanCountDownIsActive(true);
        }
        //debugger;
        actions.setProjectTasks(newTask.data);
      }
      actions.setBarCode("");
      actions.setLoading(false);
    } catch (err) {
      actions.setBarCode("");
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  removeTasksFromProjectTasks */
  removeTasksFromProjectTasks: thunk(async (actions, project, helpers) => {
    let { EmployeeID, EmployeeRefNo, ContactID, LegalName, Approver } =
      helpers.getState().user;

    try {
      project.EmployeeRefNo = { value: EmployeeRefNo };
      project.ContactID = { value: ContactID };
      project.EmployeeID = { value: EmployeeID };
      project.LegalName = { value: LegalName };
      Approver && (project.Approver = { value: Approver });

      const url = helpers.getState().apiBaseURL;

      await removeTasksFromProject(url, project);

      actions.setProjectTasks([]);

      // test to give Acumatica a second to catch up
      //await removeTasksFromProject(url, EmployeeID, projectsToDelete);
      await actions.getProjectTasks(EmployeeID);
      // added for testing

      actions.cleanupSearch();
      actions.cleanupCurrentTask();
      if (project.signout) {
        actions.signOut();
      }
      // actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  getNonRoutineOptions */
  getNonRoutineOptions: thunk(async (actions, placeholder, helpers) => {
    try {
      const url = helpers.getState().apiBaseURL;
      const options = await listNonRoutineOptions({ url });
      actions.setNonRoutineOptions(options.data);
      //actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  addNonRoutineTask */
  addNonRoutineTask: thunk(async (actions, task, helpers) => {
    task.ParentTaskRefID =
      helpers.getState().currentTask.ProjectTaskIDInt.value;
    task.WorkOrder = helpers.getState().currentTask.ProjectIDInt.value;
    task.Inspector = helpers.getState().user.EmployeeID;

    try {
      const url = helpers.getState().apiBaseURL;
      const nonRoutineTask = await addNonRoutineTaskToProject(url, task);

      const taskId =
        nonRoutineTask.data && nonRoutineTask.data.NonRoutineTaskCD
          ? nonRoutineTask.data.NonRoutineTaskCD.value
          : "";
      actions.clearNonRoutine();
      actions.setLoading(false);
      actions.setAlerts({
        error: `Non Routine ${taskId} Created Successfully`,

        type: "success",
      });
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  putInspectionRejection: thunk(async (actions, _, helpers) => {
    const currentTask = helpers.getState().currentTask;
    const currentTaskId = currentTask.ProjectTaskID.value;
    const rejectedReason = helpers.getState().rejectedReason;

    try {
      actions.setShowInspectionRejectModal(false);
      actions.setLoading(true);
      let url = helpers.getState().apiBaseURL;
      const payload = await createPutInspectionRejectionPayload({
        rejectionDescription: rejectedReason,
        projectNoteId: currentTask.ProjectNoteID.value,
        taskNoteId: currentTask.TaskNoteID.value,
      });
      const inspectionRejection = await addInspectionRejection(
        url,
        payload,
        currentTaskId
      );

      if (
        inspectionRejection.data.RejectedReason &&
        inspectionRejection.data.RejectedReason.value
      ) {
        const updatePayload = {
          reason: inspectionRejection.data.RejectedReason.value,
          projectTasks: helpers.getState().projectTasks,
          currentTaskId,
        };
        actions.updateRejectedReasonForTask(updatePayload);
      }
      actions.setLoading(false);
      actions.setRejectedReason("");
    } catch (err) {
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  getTaskCardPDF: thunk(async (actions, task, helpers) => {
    actions.setLoading(true);
    let url = helpers.getState().apiBaseURL;
    await getTaskCard(url);
    actions.setLoading(false);
  }),
};

export default projectThunks;
