import { thunk } from 'easy-peasy';

import {
  //listStockItems,
  addStockItem,
  addPartRequest,
  getPriorityLevels,
  getPartRequest,
  removeUnservicablePart,
  addUnsvcStockItem,
  getBrandingImage,
  searchItemsDB,
  getBarcodeImageAPICall,
} from '../../services/cfbsKiosk';
import { formatISOToDateMonthYear } from '../../utils/formatDates';
import { handleAcumaticaError } from '../../utils/handleAcumaticaError';
import { sumQtyAvail } from '../../utils/sumQtyAvail';

const stockItemsThunks = {
  /* #region  filter stock items */
  filterStockItems: thunk((actions, text, helpers) => {
    const stockItems = helpers.getState().stockItems;
    let rValue = stockItems.filter((i) => {
      if (i.Description && i.InventoryCD) {
        const regex = new RegExp(`${text}`, 'gi');
        return i.Description.match(regex) || i.InventoryCD.match(regex);
      } else {
        return null;
      }
    });

    if (rValue.length === 0) {
      rValue = [];
    }
    // else {
    //   rValue.forEach((p) => {
    //     const summedObj = sumQtyAvail(p);
    //     actions.setQtyStockItemToAdd(summedObj);
    //   });
    // }

    actions.setFilteredStockItems(rValue);
    actions.setShowStockItemSearchResults(true);
    actions.setLoading(false);
  }),
  /* #endregion */

  /* #region  search items */
  searchItems: thunk(async (actions, text, helpers) => {
    const url = helpers.getState().apiBaseURL;
    try {
      const items = await searchItemsDB(url, text);

      const summedItems = [];
      items.data.forEach((p) => {
        const summedObj = sumQtyAvail(p);
        summedItems.push(summedObj);
        //actions.setQtyStockItemToAdd(summedObj);
      });

      actions.setFilteredStockItems(summedItems);

      actions.setShowStockItemSearchResults(true);
      actions.setLoading(false);
    } catch (err) {
      console.log(err.response.data.message);
      if (err.response && err.response.data && err.response.data.message) {
        actions.setAlerts({
          error: err.response.data.message,
          type: 'warning',
        });
      } else {
        actions.setAlerts({
          error: 'No Items Found',
          type: 'warning',
        });
        actions.setShowStockItemSearchResults(true);
      }
      actions.setLoading(false);
    }
  }),
  /* #endregion */

  /* #region  get stock items */
  /* removed with new stock item /find
  getStockItems: thunk(async (actions, placeholder, helpers) => {
    actions.setStockItemsComplete(false);
    if (helpers.getState().stockItems) {
      actions.setLoading(false);
      return;
    }

    try {
      const url = helpers.getState().apiBaseURL;
      //const stockItems = await listStockItems(url);
      //actions.setStockItems(stockItems.data);
      //actions.setStockItemsComplete(true);
      helpers.getState().partRequestListComplete && actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: 'danger' });
    }
  }),
  */
  /* #endregion */

  /* #region  add part request */
  addPartReq: thunk(async (actions, partReqPayload, helpers) => {
    const {
      InventoryCD,
      Description,
      BaseUnit: Uom,
      Qty,
      UsrNeedBy,
      UsrPriorityLevel,
    } = partReqPayload;

    const currentTask = helpers.getState().currentTask;
    const user = helpers.getState().user;
    let itemReq = {
      InventoryID: InventoryCD,
      Description,
      Uom,
      Qty,
      ReqType: 'I',
      TaskID: currentTask.ProjectTaskID.value,
      WorkOrderID: currentTask.WorkOrderID.value,
      RequestedBy: user.AccountID_2,
      UsrNeedBy,
      UsrPriorityLevel,
    };

    if (UsrNeedBy) itemReq.UsrNeedBy = formatISOToDateMonthYear(UsrNeedBy);
    if (currentTask.UsrZoneID && currentTask.UsrZoneID.value)
      itemReq.UsrZoneID = currentTask.UsrZoneID.value;

    try {
      const url = helpers.getState().apiBaseURL;

      const part = await addPartRequest(url, itemReq);

      const summedObj = sumQtyAvail(part.data);

      actions.setQtyStockItemToAdd(summedObj);
      actions.setLoading(false);
    } catch (err) {
      actions.setStockItemToAdd(null);
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: 'danger' });
    }
  }),
  /* #endregion */

  /* #region  get parts request */
  getPartReq: thunk(async (actions, _, helpers) => {
    actions.setLoading(true);
    actions.setPartRequestListComplete(false);
    actions.setPartRequestList(null);
    const currentTask = helpers.getState().currentTask;
    // const priorityLevelOptions = helpers.getState().priorityLevelOptions;

    const taskId = currentTask.ProjectTaskID.value;

    try {
      const url = helpers.getState().apiBaseURL;
      const parts = await getPartRequest(url, taskId);

      parts.data.forEach((p) => {
        const summedObj = sumQtyAvail(p);
        actions.setQtyStockItemToAdd(summedObj);
      });

      actions.setPartRequestListComplete(true);
      actions.setLoading(false);
      // if (helpers.getState().stockItemsComplete) {
      //   actions.setLoading(false);
      // }
      actions.clearPartNotFoundItem();
      //actions.setLoading(false);
    } catch (err) {
      console.log(err);
      actions.setAlerts({ error: err.message, type: 'danger' });
    }
  }),
  /* #endregion */

  /* #region  get priority levels */
  getPriorityLevelOptions: thunk(async (actions, _, helpers) => {
    try {
      const url = helpers.getState().apiBaseURL;
      const response = await getPriorityLevels(url);
      const priorityLevels =
        response.data && response.data.length ? response.data : [];
      const formattedPriorityLevels = priorityLevels.map((pl) => {
        return {
          value: pl.PriorityLevel,
          label: pl.Description,
        };
      });
      actions.setPriorityLevelOptions(formattedPriorityLevels);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: 'danger' });
    }
  }),
  /* #endregion */

  /* #region  add stock item and add part request */
  addStockItemAndAddPartReq: thunk(async (actions, stockItem, helpers) => {
    const currentTask = helpers.getState().currentTask;
    const user = helpers.getState().user;
    const priorityLevelOptions = helpers.getState().priorityLevelOptions;
    stockItem.ReqType = 'I';
    stockItem.TaskID = currentTask.ProjectTaskID.value;
    stockItem.WorkOrderID = currentTask.WorkOrderID.value;
    stockItem.RequestedBy = user.AccountID_2;

    if (stockItem.UsrNeedBy) {
      const formattedDate = formatISOToDateMonthYear(stockItem.UsrNeedBy);
      stockItem.UsrNeedBy = formattedDate;
    }

    if (currentTask.UsrZoneID && currentTask.UsrZoneID.value)
      stockItem.UsrZoneID = currentTask.UsrZoneID.value;

    try {
      let url = helpers.getState().apiBaseURL;
      const newItem = await addStockItem(url, stockItem);

      stockItem.PartStatus = (newItem.data && newItem.data.PartStatus) || '';
      stockItem.InventoryCD =
        (newItem.data &&
          newItem.data.InventoryCD &&
          newItem.data.InventoryCD.value) ||
        '';

      const matchePriorityLevel = priorityLevelOptions.find((plo) => {
        if (plo.PriorityLevel === stockItem.UsrPriorityLevel) {
          return true;
        }
        return false;
      });

      const pLevel = matchePriorityLevel ? matchePriorityLevel.Description : '';

      stockItem.UsrPriorityLevel = pLevel;
      stockItem.InventoryCD = stockItem.InventoryID;
      actions.clearPartNotFoundItem();
      actions.addNewStockItemToRequestList(stockItem);

      actions.setLoading(false);
    } catch (err) {
      console.log(err);
      actions.clearPartNotFoundItem();
      actions.setStockItemToAdd(null);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: 'danger' });
    }
  }),

  /* #endregion */
  removeUnservicablePart: thunk(async (actions, unservicablePart, helpers) => {
    let url = helpers.getState().apiBaseURL;
    return removeUnservicablePart(url, unservicablePart).then((success) => {
      actions.setLoading(false);
      actions.clearUnserviceablePart();
      actions.clearPartNotFoundItem();
      return success;
    });
  }),

  addUnsvcStockItemThunk: thunk(
    async (actions, unsvcStockItemInput, helpers) => {
      try {
        let url = helpers.getState().apiBaseURL;
        actions.setLoading(true);
        const { user, currentTask } = helpers.getState();
        const { data } = await addUnsvcStockItem(url, {
          ...unsvcStockItemInput,
          Uom: 'EA',
          ReqType: 'I',
          TaskID: currentTask.ProjectTaskID.value,
          WorkOrderID: currentTask.WorkOrderID.value,
          RequestedBy: user.AcctCD,
        });
        actions.setShowAddUnsvcStockItem(false);
        actions.setLoading(false);
        actions.updateUnservicable({
          PartNbr: {
            InventoryCD: data.InventoryCD.value,
            InventoryID: data.InventoryID.value,
          },
          Description: data.Description.value,
        });
        actions.setShowUnservicablePopup(true);
      } catch (e) {
        actions.setLoading(false);
      }
    }
  ),
  getReportImage: thunk(async (actions, _, helpers) => {
    actions.setLoading(true);
    const logo = helpers.getState().logo;
    const imageUrl = `https://kioskbranding.blob.core.windows.net/images/${logo}`;
    const branding = await getBrandingImage(imageUrl);

    return branding;
  }),
  getBarcodeImageThunk: thunk(async (actions, ProjectTaskID, helpers) => {
    actions.setLoading(true);
    const barcode = await getBarcodeImageAPICall(ProjectTaskID);
    return barcode;
  }),
};

export default stockItemsThunks;
