import { centerText } from "../utilities/document";
import { DEFAULT_FONT_SIZE, drawBox } from "./utilities/document";

export const showInspector = (doc, startX, startY) => {
    const boxWidth = ((doc.page.width - 50) - startX);
    const boxHeight = 183;
    
    drawBox(doc, startX, startY + 1, boxWidth, boxHeight, {
        lineWidth: 2,
    });

    doc.fontSize(8);
    centerText(doc, "INSPECTOR", boxWidth, startX, startY + 5);
    centerText(doc, "/ Date", boxWidth, startX, doc.y + 12);
    doc.fontSize(6);
    centerText(doc, "(DD/MM/YYYY)", boxWidth, startX, doc.y + 12);

    doc.fontSize(DEFAULT_FONT_SIZE);

    drawBox(doc, 50.5, 485, doc.page.width - 100.5, 50, {
        lineWidth: 2,
    });

    doc
        .fontSize(8.75)
        .text("THE WORK SPECIFIED EXCEPT AS OTHERWISE SPECIFIED HAS BEEN PERFORMED TO THE STANDARDS REQUIRED", 55, 490)
        .text("BY STS MAINTENANCE ORGANISATION EXPOSITION REF. STS/MOE/01 UNDER EASA PART 145 APPROVAL", 55, doc.y + 10)
        .text("EASA.UK.145.01251.", 55, doc.y + 10)
        .fontSize(DEFAULT_FONT_SIZE);

    drawBox(doc, startX + 3, startY + boxHeight + 1, boxWidth - 6, 1, {
        lineWidth: 4,
        strokeColor: "white",
    });
}