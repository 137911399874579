import blobStream from "blob-stream";
import PDFDocument from "@react-pdf/pdfkit";
import showHeader from "./header";
import { showFillInBlankTable, X_START } from "./fillinblank";

const createUnserviceableCard = (
  companyLogoBlob,
  {
    PartNbr,
    SerialNbr,
    Quantity,
    StateShelfLife,
    Description,
    Station,
    TechLogRef,
    ReasonforRemoval,
    Inspector,
    Date,
    EquipID,
    Position,
  }
) => {
  try {
    const doc = new PDFDocument({
      size: "C5",
    });
    const stream = doc.pipe(blobStream());

    doc.x = X_START;

    showHeader(doc, {
      companyLogoBlob,
    });

    showFillInBlankTable(doc, [
      [
        { label: "PART NO", value: PartNbr, lineWidth: 130 },
        { label: "MOD STATE/SHELF LIFE", value: StateShelfLife, lineWidth: 70 },
      ],
      [
        { label: "SERIAL NO", value: SerialNbr, lineWidth: 190 },
        { label: "QTY", value: Quantity, lineWidth: 85 },
      ],
      [
        { label: "DESCRIPTION", value: Description, lineWidth: 147 },
        { label: "POSITION", value: Position, lineWidth: 90 },
      ],
      [
        { label: "A/C REG'N/ESN", value: EquipID, lineWidth: 70 },
        { label: "STATION", value: Station, lineWidth: 71 },
        { label: "DATE", value: Date, lineWidth: 71 },
      ],
      [
        {
          label: "REASON FOR REMOVAL",
          value: ReasonforRemoval,
          lineWidth: 240,
        },
      ],
      [
        { label: "INSPECTOR", value: Inspector, lineWidth: 125 },
        { label: "TECH LOG REF", value: TechLogRef, lineWidth: 98 },
      ],
    ]);

    doc
      .fontSize(7)
      .font("Helvetica-Bold")
      .text("ENG 601", doc.page.width - 85, doc.y + 15);

    doc.end();

    stream.on("finish", function () {
      // get a blob you can do whatever you like with
      // const blob = stream.toBlob('application/pdf');
      // or get a blob URL for display in the browser
      const url = stream.toBlobURL("application/pdf");
      window.open(url, "_blank");
    });
  } catch (err) {
    console.log(err);
  }
};

export default createUnserviceableCard;
