import React, { useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

const ProjectTasksFilter = () => {
  const { filteredProjectTasks } = useStoreState((state) => ({
    filteredForms: state.filteredForms,
  }));

  const { clearProjectTasksFilter, setFilteredProjectTasks } = useStoreActions(
    (state) => ({
      clearProjectTasksFilter: state.clearProjectTasksFilter,
      setFilteredProjectTasks: state.setFilteredProjectTasks,
    })
  );

  const text = useRef('');

  useEffect(() => {
    if (filteredProjectTasks === null) {
      text.current.value = '';
    }
  });

  const onChange = (e) => {
    if (text.current.value !== '') {
      setFilteredProjectTasks(e.target.value);
    } else {
      clearProjectTasksFilter();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <input
        ref={text}
        type="text"
        placeholder="Filter Project Tasks"
        onChange={onChange}
      />
    </form>
  );
};

export default ProjectTasksFilter;
