import React from 'react';
import PropTypes from 'prop-types';
import { useStoreActions } from 'easy-peasy';

const ProjectItem = ({ project, index, backgroundColor }) => {
  const { ProjectID, Description, Customer, Status, EquipmentID } = project;

  // const { qualifications } = useStoreState((state) => ({
  //   qualifications: state.qualifications,
  // }));

  const {
    setFilteredFoundProject,
    getTaskForFilteredProject,
    setLoading,
  } = useStoreActions((actions) => ({
    setFilteredFoundProject: actions.setFilteredFoundProject,
    getTaskForFilteredProject: actions.getTaskForFilteredProject,
    setLoading: actions.setLoading,
  }));

  const findTasks = (e) => {
    const projectId = e.target.getAttribute('value');

    setFilteredFoundProject(projectId);
    getTaskForFilteredProject(projectId);
    setLoading(true);
    // setAlerts({
    //   error: `FIND TASKS for ${projectId} function selected`,
    //   type: 'warning',
    // });
  };

  // const qualified = true;
  // qualified = qualifications[Customer] ? true : false;
  // const getTasks = (project) => {};

  return (
    <div
      className={
        backgroundColor === 'light'
          ? // ? qualified
            //   ? 'card-slim bg-light mt-1'
            //   : 'card-slim bg-warning mt-1'
            'card-slim bg-light mt-1'
          : 'card-slim bg-success-opac mt-1'
      }
      // onClick={qualified ? findTasks : undefined}
      onClick={findTasks}
      value={ProjectID}
    >
      <div className="grid-2" value={ProjectID}>
        <div value={ProjectID}>
          <span
            className="m-2"
            style={{ fontSize: '10px', position: 'relative' }}
            value={ProjectID}
          >
            {Status}
          </span>
          <h4
            className="text-primary text-left project-description"
            value={ProjectID}
          >
            {Customer} - {EquipmentID}
          </h4>
          <div className="task-description" value={ProjectID}>
            {Description}
          </div>
        </div>
        <div>
          <span
            className="m-2"
            style={{ fontSize: '10px', position: 'relative' }}
            value={ProjectID}
          ></span>
          <h4 className="text-right mr-2" value={ProjectID}>
            Project {ProjectID}
          </h4>
          <div className="task-description text-danger" value={ProjectID}>
            {/* {!qualified ? 'Not Qualified' : ''} */}
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectItem.propTypes = {
  project: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default ProjectItem;
