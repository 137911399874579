import { centerText } from "../utilities/document";
import { drawBox } from "./utilities/document"

export const showInfoWithCustomer = (
    doc, 
    {
        ProjectTaskID,
        Qty,
        Customer,
        JobCode,
        RaisedBy,
        DateRaised,
    }, 
    startX, 
    startY
) => {
    const columns = [
        {
            label: "Customer",
            value: Customer,
        },
        {
            label: "Generating Item",
            value: ProjectTaskID,
        },
        {
            label: "Raised By",
            value: RaisedBy,
        },
        {
            label: "Date Raised",
            value: DateRaised,
            options: {
                bold: false,
            },
        },
        {
            label: "Est Man-Hrs",
            value: Qty,
        },
        {
            label: "Job Code",
            value: JobCode,
        },
    ];
    let cursorX = startX;

    drawBox(doc, cursorX, startY, doc.page.width - 100, 40);

    cursorX += 5;

    columns.forEach(({ label, value, options={ bold: true }, offset=15 }) => {
        const width = doc.widthOfString((label.length > value.length) ? label : value);
        centerText(doc, label, width, cursorX, startY + 5);
        centerText(doc, value, width, cursorX, startY + 18, options);
        cursorX += width + offset;
    });
}