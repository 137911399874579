import React from 'react';

import ProjectSearch from '../searchProject/ProjectSeach';
import Projects from '../searchProject/Projects';

const SearchProjects = () => {
  return (
    <div className="inner-scroll">
      <ProjectSearch />
      <Projects />
    </div>
  );
};

export default SearchProjects;
