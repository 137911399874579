import React, { Fragment } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

const StockItemQty = () => {
  const {
    stockItemToAdd,
    loading,
    showStockItemQty,
    priorityLevelOptions,
    partRequestList,
    enableStockItemAdd,
  } = useStoreState((state) => ({
    stockItemToAdd: state.stockItemToAdd,
    loading: state.loading,
    showStockItemQty: state.showStockItemQty,
    priorityLevelOptions: state.priorityLevelOptions,
    partRequestList: state.partRequestList,
    enableStockItemAdd: state.enableStockItemAdd,
  }));

  const { setLoading, addPartReq, setShowStockItemQty, setPartItemRequest } =
    useStoreActions((actions) => ({
      setLoading: actions.setLoading,
      addPartReq: actions.addPartReq,
      setShowStockItemQty: actions.setShowStockItemQty,
      setPartItemRequest: actions.setPartItemRequest,
    }));

  const { Qty, UsrNeedBy } = stockItemToAdd;

  const toggle = () => {
    setShowStockItemQty(false);
  };

  const addQtyToStockItemToAdd = (e) => {
    if (Qty > 0) {
      setLoading(true);
      addPartReq(stockItemToAdd);
      toggle();
    }
  };

  const onChange = (e) => {
    let value = e.target.value;

    if (e.target.name === 'UsrPriorityLevel') {
      const matchedOption = priorityLevelOptions.find(
        (plo) => plo.Description === value
      );

      if (matchedOption) {
        value = matchedOption.PriorityLevel;
      }
    }
    setPartItemRequest({ key: e.target.name, value });
  };

  const setDate = (e) => {
    setPartItemRequest({
      key: 'UsrNeedBy',
      value: e,
    });
  };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   addPartReq(stockItemToAdd);
  //   toggle();
  // };

  const checkForOverlapAndDisplayWarning = (stockItem, partslist) => {
    if (stockItem && partslist) {
      const stockItemInventoryId =
        (stockItem.InventoryCD && stockItem.InventoryCD.trim()) || '';
      const isOverlap = partslist.some((pl) => {
        if (pl.InventoryCD && pl.InventoryCD.trim() === stockItemInventoryId) {
          return true;
        }
        return false;
      });

      if (isOverlap) {
        return (
          <div className="mb-4 text-warning">
            Warning Part Exists on this Task
          </div>
        );
      }
      return null;
    }
    return null;
  };

  return (
    <Fragment>
      {stockItemToAdd !== null && !loading && (
        <Modal
          isOpen={showStockItemQty}
          centered={true}
          size={'lg'}
          backdrop={true}
        >
          <ModalHeader>Part Quantity</ModalHeader>
          <ModalBody className="text-center">
            {checkForOverlapAndDisplayWarning(stockItemToAdd, partRequestList)}
            <div className="mb-4 text-primary">
              How many {stockItemToAdd.InventoryCD}'s needed (Unit of Measure ={' '}
              {stockItemToAdd.BaseUnit})
            </div>
            <div style={{ overflowY: 'scroll' }}>
              <form /*onSubmit={onSubmit}*/>
                <div>
                  <input
                    className="m-3 p-2 text-indent"
                    name="Qty"
                    type="number"
                    placeholder="Quantity (required)"
                    value={Qty}
                    autoFocus
                    min="0"
                    onChange={onChange}
                    required={true}
                  />
                </div>
                {/* <div>Select Priority Level</div> */}
                <div className="select-custom-wrapper">
                  <select
                    className="select-custom-large text-indent"
                    onChange={onChange}
                    name="UsrPriorityLevel"
                    defaultValue={'DEFAULT'}
                  >
                    <option value="DEFAULT" disabled>
                      Select Priority Level
                    </option>
                    {priorityLevelOptions.map((plo) => (
                      <option key={plo.PriorityLevel} value={plo.PriortyLevel}>
                        {plo.Description}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <DatePicker
                    className="datepicker-custom-large text-indent"
                    selected={UsrNeedBy}
                    //onChange={(date) => setNbd(date)}
                    onChange={setDate}
                    placeholderText="Need By Date"
                  />
                </div>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="grid-2">
              <Button
                color="light"
                style={{ display: 'block', width: '100%' }}
                //onClick={toggle}
                onClick={toggle}
              >
                Cancel
              </Button>
              <Button
                color="success"
                style={{ display: 'block', width: '100%' }}
                onClick={addQtyToStockItemToAdd}
                disabled={!enableStockItemAdd}
              >
                Set Quantity
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

export default StockItemQty;
