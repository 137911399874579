import employeeStore from "./employee";
import projectStore from "./projects";
import stockItemStore from "./stockItems";
//import startUpVals from '../startUpVals';

//always use USPROMRO****** for test and dev
// console.log('App URL: ', process.env.REACT_APP_APIBASEURL);
const initialState = {
  apiBaseURL:
    //  /* Production */ 'https://cfbskioskprod.azurewebsites.net',
    /* Developer Testing */ "https://cfbskiosk.azurewebsites.net",

  // /* STS Production Internal */ "https://kioskAPI.stsholding.com",
  // /* STS Test Internal */ "https://testKioskAPI.stsholding.com",

  // /* Docker */ 'https://https://stsshopdev.azurewebsites.net',

  // "http://localhost:8080/",

  loginInput: {
    email: "",
    password: "",
  },
  usingCentrik: false,
  page: "home",
  user: null,
  showUserWarning: false,
  qualifications: {
    customer: null,
    personal: null,
    mechanicQualified: null,
    inspectorQualified: null,
    boroscopeQualified: null,
    failedQualification: null,
  },
  loading: false,
  favicon: localStorage.getItem("favicon"),
  logo: localStorage.getItem("logo"),
  spinner: localStorage.getItem("spinner"),
  tenant: "",
  alerts: [],
  queueLocked: false,
  showForcedLogoutModal: false,
  queueScanLockSeconds: null,
  queueScanCountDownIsActive: false,
  projectTasks: null,
  taskLimit: false,
  filteredProjectTasks: null,
  projectSearchValue: "",
  foundProjects: null,
  filteredTasks: null,
  filteredFoundProjects: null,
  tasksByFilteredFoundProject: null,
  currentTask: null,
  showInspectorButtons: false,
  showNonRoutineButton: true,
  carouselItems: ["TaskItem", "PartsRequest", "NonRoutineItem"],
  activeCarouselIndex: 0,
  carouselAnimating: false,
  carouselInterval: false,
  stockItemSearchValue: "",
  showStockItemSearchResults: false,
  partRequestList: null,
  partRequestListComplete: false,
  reportingItemReq: null,
  priorityLevelOptions: [],
  showStockItemQty: false,
  stockItems: null,
  stockItemsComplete: false,
  filteredStockItems: null,
  stockItemToAdd: {
    Qty: 0,
    UsrNeedBy: "",
  },
  partNotFoundItem: {
    InventoryID: "",
    Description: "",
    Qty: 0,
    UsrNeedBy: "",
    UsrPriorityLevel: "",
    note: "",
  },
  enableStockItemAdd: false,
  showAddNewStockItem: false,
  showAddUnsvcStockItem: false,
  showInspectionRejectModal: false,
  isRejected: false,
  rejectedReason: "",
  nonRoutineTask: {
    title: "",
    detail: "",
    ataChhapterID: "",
    ataSubChapterID: "",
    zoneID: "",
    isNone: "",
    isEWIS: "",
    isRII: "",
    isCriticalTask: "",
    isCDCCL: "",
    isAWLI: "",
    hours: "",
  },
  nonRoutineOptions: null,
  showNonRoutineOptions: false,
  nonRoutineOptionType: "",
  optionsFilterText: "",
  filteredNonRoutineOptions: null,
  filteredNonRoutingSubOptions: null,
  ataChapterID: "",
  ataSubChapterID: "",
  zoneID: "",
  laborItemID: "",
  correctiveAction: "",
  barCode: "",
  showUnservicablePopup: false,
  unservicable: {
    PartNbr: {
      InventoryCD: "",
      InventoryID: 0,
    },
    SerialNbr: "",
    StateShelfLife: "",
    Description: "",
    Quantity: 0,
    Date: "",
    Station: "",
    Owner: "",
    TechLogRef: "",
    Operator: "",
    ReasonforRemoval: "",
  },
  logoU8string: null,
  showCentrikButton: false,
};

const stateStore = {
  ...initialState,
  ...employeeStore,
  ...projectStore,
  ...stockItemStore,
};

export default stateStore;
