import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

const InspectionReject = () => {
  const { showInspectionRejectModal } = useStoreState((state) => ({
    showInspectionRejectModal: state.showInspectionRejectModal,
  }));

  const {
    setShowInspectionRejectModal,
    setRejectedReason,
    putInspectionRejection,
  } = useStoreActions((actions) => ({
    setShowInspectionRejectModal: actions.setShowInspectionRejectModal,
    setRejectedReason: actions.setRejectedReason,
    putInspectionRejection: actions.putInspectionRejection,
  }));

  const toggle = () => {
    setShowInspectionRejectModal(false);
    setRejectedReason('');
  };

  const submit = () => {
    putInspectionRejection();
  };

  return (
    <Modal
      isOpen={showInspectionRejectModal}
      centered={true}
      size={'lg'}
      backdrop={true}
    >
      <ModalHeader>Inspection Rejection</ModalHeader>
      <ModalBody className="text-center">
        <div className="mb-4 text-primary">
          Briefly describe the reason for rejecting this inspection
        </div>
        <div style={{ height: '50vh', overflowY: 'scroll' }}>
          <textarea
            placeholder="Reason for rejection"
            name="notes"
            className="mt-0 mb-4"
            onChange={(e) => {
              setRejectedReason(e.target.value);
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="grid-2 w-100">
          <Button
            color="light"
            style={{ display: 'block', width: '100%' }}
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            style={{ display: 'block', width: '100%' }}
            onClick={() => {
              submit();
            }}
          >
            Submit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default InspectionReject;
