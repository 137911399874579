import React, { Fragment } from 'react';
import ProjectTasksFilter from './ProjectTasksFilter';
import ProjectTaskItem from './ProjectTaskItem';
import ForcedLogout from '../layout/ForcedLogout';
import Spinner from '../layout/Spinner';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStoreState } from 'easy-peasy';
import { v4 } from 'uuid';

const ProjectTasks = () => {
  const {
    projectTasks,
    filteredProjectTasks,
    loading,
    showForcedLogoutModal
  } = useStoreState((state) => ({
    projectTasks: state.projectTasks,
    filteredProjectTasks: state.filteredProjectTasks,
    loading: state.loading,
    showForcedLogoutModal: state.showForcedLogoutModal
  }));

  return (
    <Fragment>
      {showForcedLogoutModal ? (
        <ForcedLogout />
      ) : (
        <Fragment>
          <ProjectTasksFilter />
          <div className="inner-scroll">
            {projectTasks !== null &&
            Array.isArray(projectTasks) &&
            !loading ? (
              <TransitionGroup>
                {projectTasks.length > 0 ? (
                  filteredProjectTasks !== null ? (
                    filteredProjectTasks.map((t) => (
                      <CSSTransition
                        exit={false}
                        key={t.id}
                        timeout={500}
                        classNames="item"
                      >
                        <ProjectTaskItem task={t} />
                      </CSSTransition>
                    ))
                  ) : (
                    projectTasks.map((t) => (
                      <CSSTransition
                        exit={false}
                        key={t.id}
                        timeout={500}
                        classNames="item"
                      >
                        <ProjectTaskItem task={t} />
                      </CSSTransition>
                    ))
                  )
                ) : (
                  <CSSTransition
                    enter={false}
                    exit={false}
                    key={v4()}
                    timeout={500}
                    classNames="item"
                  >
                    <div className="card">
                      <h2>
                        No <span className="text-primary">Project</span> Tasks
                        found
                      </h2>
                    </div>
                  </CSSTransition>
                )}{' '}
              </TransitionGroup>
            ) : (
              <Spinner />
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProjectTasks;
