import React, { Fragment } from 'react';
import NonRoutineOptions from '../nonRoutineModal/NonRoutineOptions';
import { useStoreState, useStoreActions } from 'easy-peasy';
import createNonRoutineTaskCard from '../../reports/nonRoutine';

const NonRoutineItem = () => {
  const {
    showNonRoutineOptions,
    ataChapterID,
    ataSubChapterID,
    zoneID,
    laborItemID,
    nonRoutineTask,
    currentTask,
    user,
  } = useStoreState((state) => ({
    showNonRoutineOptions: state.showNonRoutineOptions,
    ataChapterID: state.ataChapterID,
    ataSubChapterID: state.ataSubChapterID,
    zoneID: state.zoneID,
    laborItemID: state.laborItemID,
    nonRoutineTask: state.nonRoutineTask,
    currentTask: state.currentTask,
    user: state.user,
  }));

  const {
    setLoading,
    addNonRoutineTask,
    setAlerts,
    setShowNonRoutineOptions,
    setNonRoutineOptionType,
    setNonRoutineTaskItem,
    clearNonRoutineTaskItemExceptNone,
    setAtaSubChapterID,
    setZoneID,
    getReportImage,
    getBarcodeImageThunk,
  } = useStoreActions((actions) => ({
    setLoading: actions.setLoading,
    addNonRoutineTask: actions.addNonRoutineTask,
    setAlerts: actions.setAlerts,
    setShowNonRoutineOptions: actions.setShowNonRoutineOptions,
    setNonRoutineOptionType: actions.setNonRoutineOptionType,
    setNonRoutineTaskItem: actions.setNonRoutineTaskItem,
    clearNonRoutineTaskItemExceptNone:
      actions.clearNonRoutineTaskItemExceptNone,
    setAtaSubChapterID: actions.setAtaSubChapterID,
    setZoneID: actions.setZoneID,
    getReportImage: actions.getReportImage,
    getBarcodeImageThunk: actions.getBarcodeImageThunk,
  }));

  let showLaborItems = false;
  currentTask.nonRoutineLaborItems &&
    (showLaborItems = currentTask.nonRoutineLaborItems);

  let requireLaborItems = false;
  currentTask.requireNonRoutineLaborItems &&
    (requireLaborItems = currentTask.requireNonRoutineLaborItems);

  const {
    title,
    defect,
    isNone,
    isEWIS,
    isRII,
    isCriticalTask,
    isCDCCL,
    isAWLI,
    hours,
  } = nonRoutineTask;

  const customLabels =
    user && user.customLabels && user.customLabels.nonRoutine
      ? user.customLabels.nonRoutine
      : null;

  const specialNonRoutineTypes =
    user && user.specialNonRoutineTypes ? user.specialNonRoutineTypes : [];

  specialNonRoutineTypes &&
    specialNonRoutineTypes.forEach(
      (st) => !nonRoutineTask[st.abr] && (nonRoutineTask[st.abr] = '')
    );

  const onSubmit = async (e) => {
    e.preventDefault();

    if (showLaborItems && requireLaborItems && (!hours || !laborItemID)) {
      setAlerts({
        error: 'Hours and Labor items are required!',
        type: 'warning',
      });

      return;
    }

    if (
      title !== '' &&
      defect !== ''
      //ataChapterID !== '' &&
      //ataSubChapterID !== '' &&
      //zoneID !== ''
    ) {
      let task = {
        Title: title,
        Defect: defect,
        ATAChapterID: ataChapterID,
        ATASubChapterID: ataSubChapterID,
        ZoneID: zoneID,
        None: isNone,
        EWIS: isEWIS,
        RII: isRII,
        CriticalTask: isCriticalTask,
        CDCCL: isCDCCL,
        // SHMNT: isSHMNT,
        // SHMNTDEF: isSHMNTDEF,
        AirworthinessLimitationsItems: isAWLI,
        Qty: hours,
        InventoryID: laborItemID,
        ProjectTaskID: currentTask.ProjectTaskID.value,
        WorkOrderID: currentTask.WorkOrderID.value,
        Customer: currentTask.Customer.value,
        JobCode: currentTask.JobCode.value,
        RaisedBy: user.EmployeeRefNo,
        DateRaised: currentTask.Time.value,
        EquipModel: currentTask.EquipModel.value,
        EquipID: currentTask.EquipID.value,
      };

      specialNonRoutineTypes &&
        specialNonRoutineTypes.forEach(
          (st) => (task[st.abr] = nonRoutineTask[st.abr])
        );

      if (currentTask.STSZone && currentTask.STSZone.value)
        task.STSZone = currentTask.STSZone.value;
      if (currentTask.UsrZoneID && currentTask.UsrZoneID.value)
        task.UsrZoneID = currentTask.UsrZoneID.value;

      setLoading(true);
      addNonRoutineTask(task);

      if (currentTask.printNonRoutineTaskCard) {
        const [blob, barcodeBlob] = await Promise.all([
          getReportImage(),
          getBarcodeImageThunk(currentTask.ProjectTaskID.value),
        ]);
        createNonRoutineTaskCard(blob, barcodeBlob, task);
      }
    } else {
      setAlerts({
        error: 'Title and Defect are required fields',
        type: 'danger',
      });
    }
  };

  const selectOption = (e) => {
    const type = `${e.target.name}s`;
    setNonRoutineOptionType(type);
    type === 'chapters' && setAtaSubChapterID('') && setZoneID('');
    type === 'subChapters' && setZoneID('');
    setShowNonRoutineOptions(true);
  };

  const onChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (e.target.name === 'isNone' && e.target.checked) {
        clearNonRoutineTaskItemExceptNone();
      }
      setNonRoutineTaskItem({ key: e.target.name, value: e.target.checked });
    } else {
      setNonRoutineTaskItem({ key: e.target.name, value: e.target.value });
    }
  };

  return (
    <Fragment>
      {showNonRoutineOptions ? (
        <NonRoutineOptions />
      ) : (
        <form onSubmit={onSubmit}>
          <div>
            <label htmlFor="title" className="w-100 text-left my-1">
              Title
            </label>
            <input
              name="title"
              className="form-control  my-1"
              placeholder="Title"
              value={title}
              onChange={onChange}
              required={true}
              autoComplete="off"
            />
          </div>
          <label htmlFor="detail" className="w-100 text-left my-1">
            Detail
          </label>
          <textarea
            className="form-control  my-1"
            name="defect"
            rows="3"
            placeholder="Defect"
            value={defect}
            onChange={onChange}
            autoComplete="off"
          ></textarea>
          <div className="grid-3">
            <div>
              <label htmlFor="chapter" className="w-100 text-left my-1">
                Chapter
              </label>
              <input
                name="chapter"
                className="form-control  my-1"
                placeholder="ATA Chapter"
                value={ataChapterID}
                onFocus={selectOption}
                readOnly={true}
              ></input>
            </div>
            <div>
              <label htmlFor="subChapter" className="w-100 text-left my-1">
                Sub-Chapter
              </label>
              <input
                name="subChapter"
                className="form-control  my-1"
                placeholder="Sub-chapter"
                value={ataSubChapterID}
                onFocus={selectOption}
                disabled={!ataChapterID}
                readOnly={true}
              ></input>
            </div>
            <div>
              <label htmlFor="zone" className="w-100 text-left my-1">
                Zone
              </label>
              <input
                name="zone"
                className="form-control  my-1"
                placeholder="Zone"
                value={zoneID}
                onFocus={selectOption}
                disabled={!ataChapterID || !ataSubChapterID}
                readOnly={true}
              />
            </div>
          </div>
          <div className="grid-3">
            <div>
              <label htmlFor="isNone" className="w-100 text-left">
                <input
                  type="checkbox"
                  name="isNone"
                  checked={isNone}
                  onChange={onChange}
                  className="my-1"
                />{' '}
                {customLabels && customLabels.None ? customLabels.None : 'None'}
              </label>
            </div>
            <div>
              <label htmlFor="isEWIS" className="w-100 text-left">
                <input
                  type="checkbox"
                  name="isEWIS"
                  checked={isEWIS}
                  disabled={isNone}
                  onChange={onChange}
                  className="my-1"
                />{' '}
                {customLabels && customLabels.EWIS ? customLabels.EWIS : 'EWIS'}
              </label>
            </div>
            <div>
              <label htmlFor="isRII" className="w-100 text-left">
                <input
                  type="checkbox"
                  name="isRII"
                  checked={isRII}
                  disabled={isNone}
                  onChange={onChange}
                  className="my-1"
                />{' '}
                {customLabels && customLabels.RII ? customLabels.RII : 'RII'}
              </label>
            </div>
            <div>
              <label htmlFor="isCriticalTask" className="w-100 text-left">
                <input
                  type="checkbox"
                  name="isCriticalTask"
                  checked={isCriticalTask}
                  disabled={isNone}
                  onChange={onChange}
                  className="my-1"
                />{' '}
                {customLabels && customLabels.CriticalTask
                  ? customLabels.CriticalTask
                  : 'Critical Task'}
              </label>
            </div>
            <div>
              <label htmlFor="isCDCCL" className="w-100 text-left">
                <input
                  type="checkbox"
                  name="isCDCCL"
                  checked={isCDCCL}
                  disabled={isNone}
                  onChange={onChange}
                  className="my-1"
                />{' '}
                {customLabels && customLabels.CDCCL
                  ? customLabels.CDCCL
                  : 'CDCCL'}
              </label>
            </div>
            <div>
              <label htmlFor="isAWLI" className="w-100 text-left">
                <input
                  type="checkbox"
                  name="isAWLI"
                  checked={isAWLI}
                  disabled={isNone}
                  onChange={onChange}
                  className="my-1"
                />{' '}
                {customLabels && customLabels.AWLI
                  ? customLabels.AWLI
                  : 'Airworthiness Limitation'}
              </label>
            </div>
            {specialNonRoutineTypes.map((t) => {
              return (
                <div key={t.abr}>
                  <label htmlFor={t.abr} className="w-100 text-left">
                    <input
                      type="checkbox"
                      name={t.abr}
                      checked={nonRoutineTask[t.abr]}
                      disabled={isNone}
                      onChange={onChange}
                      className="my-1"
                    />{' '}
                    {t.name}
                  </label>
                </div>
              );
            })}
            <div></div>
            <div hidden={!showLaborItems}>
              <label htmlFor="hours" className="w-100 text-left my-1">
                Hours
              </label>
              <input
                type="text"
                name="hours"
                className="my-0"
                placeholder="Hours"
                value={hours}
                onChange={onChange}
              />
            </div>
            <div hidden={!showLaborItems}>
              <label htmlFor="laborItem" className="w-100 text-left my-1">
                Labor Item
              </label>
              <input
                type="text"
                name="laborItem"
                className="my-0"
                placeholder="Labor Item"
                value={laborItemID}
                onFocus={selectOption}
                readOnly={true}
                // onChange={onChange}
              />
            </div>
          </div>
          <button className="btn btn-primary my-3 w-100" onClick={onSubmit}>
            Save Non-Routine Task
          </button>{' '}
        </form>
      )}
    </Fragment>
  );
};

export default NonRoutineItem;
