import React, { Fragment, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import UserWarning from '../userWarningModal/UserWarning';
import ProjectTasks from '../projectTasks/ProjectTasks';
import Spinner from '../layout/Spinner';

const Home = () => {
  const { loading, user, showUserWarning, favicon, logo } = useStoreState(
    (state) => ({
      loading: state.loading,
      user: state.user,
      showUserWarning: state.showUserWarning,
      tenant: state.tenant,
      favicon: state.favicon,
      logo: state.logo,
    })
  );

  const { getEmployee, setLoading } = useStoreActions((actions) => ({
    setLocalStorageToken: actions.setLocalStorageToken,
    getEmployee: actions.getEmployee,
    setLoading: actions.setLoading,

    setLogo: actions.setLogo,
  }));

  useEffect(() => {
    const faviconId = document.getElementById('favicon');
    let faviconURL =
      'https://kioskbranding.blob.core.windows.net/images/favicon.ico';
    favicon &&
      (faviconURL = `https://kioskbranding.blob.core.windows.net/images/${favicon}`);
    faviconId.href = faviconURL;
  }, [favicon]);

  const onSubmit = (e) => {
    e.preventDefault();
    const scannedValue = document.querySelector('input[name="scannedValue"]')
      .value;
    setLoading(true);
    getEmployee(scannedValue);
  };

  return (
    <Fragment>
      {!loading ? (
        <Fragment>
          {!user ? (
            <Fragment>
              <div className="jumbotron text-center" id="jumbo">
                <img
                  src={`https://kioskbranding.blob.core.windows.net/images/${logo}`}
                  alt=""
                  style={{ width: '350px' }}
                />
              </div>
              <h1 className="text-center">ProMRO Sign In</h1>
              <div
                className="text-center text-gray"
                style={{ fontSize: '6em' }}
              >
                <i className="fa fa-credit-card" />
                <h3>Scan badge or enter ID to Sign-in</h3>
              </div>
              <form onSubmit={onSubmit}>
                <div className="grid-3">
                  <div className="" />
                  <input
                    className="text-center"
                    type="password"
                    name="scannedValue"
                    autoFocus
                    autoComplete="off"
                  />
                  <div className="" />
                </div>
              </form>
            </Fragment>
          ) : (
            <Fragment>
              {showUserWarning ? (
                <UserWarning
                  title="Kronos Alert"
                  message="Please clock into Kronos"
                  type="warning"
                />
              ) : (
                ''
              )}

              <ProjectTasks />
              
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default Home;
