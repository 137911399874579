const { DEFAULT_FONT } = require("../nonRoutine/utilities/document");

exports.DESCRIPTION_FONT_SIZE = 6;

exports.makeNewPage = (doc, pageNum, itemReq, blob) => {
  const page = doc.addPage({ layout: "landscape" });
  const { CreatedAt } = itemReq;

  page.image(blob, 15, 30, {
    fit: [75, 75],
  });

  page.font("Helvetica-Bold");
  page.fontSize(16).text(`Parts Requisition`, 230, 40);
  page.fontSize(10);
  page.font("Helvetica");
  page.fontSize(this.DESCRIPTION_FONT_SIZE);

  page.text("Date:", 680, 40, 80);
  page.text(CreatedAt, 720, 40, 100);
  page.text("Page:", 680, 55);
  page.text(`${pageNum} of ${page._pageBuffer.length}`, 720, 55);

  return page;
};

exports.makeSection = (page, sectionName, x, y) => {
  return (
    page
      .font("Helvetica-Bold")
      .fontSize(10)
      .text(sectionName, x, y) //, {
      // underline: true,
      //})
      .font("Helvetica")
      .fontSize(this.DESCRIPTION_FONT_SIZE)
  );
};

exports.makeSubsection = (page, name, x, y, width = 300) => {
  return page.text(name, x, y, {
    // underline: true,
    width,
  });
};

exports.makeSubsectionHeading = (page, name, x, y, width = 300) => {
  return page
    .font("Helvetica-Bold")
    .text(name, x, y, {
      // underline: true,
      width,
    })
    .font("Helvetica");
};

exports.makeLine = (page, width, start, end) => {
  return page
    .lineWidth(width)
    .moveTo(start[0], start[1])
    .lineTo(end[0], end[1])
    .stroke();
};

exports.underlineText = (doc, text, x, y) => {
  doc
    .text(text, x, y)
    .rect(x, y + 10, doc.widthOfString(text), 0)
    .stroke();
};

exports.centerText = (doc, text, width, x, y, options = {}) => {
  doc
    .font(options.bold ? "Helvetica-Bold" : DEFAULT_FONT)
    .text(text, x + (width - doc.widthOfString(text)) / 2, y, options)
    .font(DEFAULT_FONT);
};
