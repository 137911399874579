import { 
    DEFAULT_FONT, 
    DEFAULT_FONT_SIZE 
} from './utilities/document';

export const showHeader = (doc, { Title, WorkOrderID }) => {
    doc
        .font("Helvetica-Bold")
        .fontSize(14)
        .text(Title, 50, 12)
        .font(DEFAULT_FONT)
        .fontSize(DEFAULT_FONT_SIZE);

    doc
        .font("Helvetica-Bold")
        .fontSize(16)
        .text(WorkOrderID, doc.page.width - doc.widthOfString(WorkOrderID) - 50, 12)
        .font(DEFAULT_FONT)
        .fontSize(DEFAULT_FONT_SIZE);
}