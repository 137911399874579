import React, { Fragment } from "react";
import PartRequestListItem from "./partRequestListItem";
import { useStoreState } from "easy-peasy";
import { v4 } from "uuid";

const PartRequestList = () => {
  const { loading, partRequestList } = useStoreState((state) => ({
    loading: state.loading,
    partRequestList: state.partRequestList,
  }));

  return (
    <Fragment>
      {partRequestList !== null && (
        <div className="light card-slim bg-primary mt-3 pb-1">
          <div className="grid-5 my-3">
            <div className=" mt-3 ml-3 text-left">Inventory CD</div>
            <div className=" mt-3 ml-3 text-left">Priority Lvl.</div>
            <div className=" mt-3 ml-3 text-left">Need By</div>
            <div className=" mt-3 ml-3 text-left">Quantity</div>
            <div className=" mt-3 ml-3 text-left">Status</div>
          </div>
        </div>
      )}
      <div className="">
        {partRequestList !== null && !loading && (
          <Fragment>
            {partRequestList.length > 0 ? (
              partRequestList.map((p) => (
                <div key={v4()} timeout={500} className="item">
                  <PartRequestListItem part={p} />
                </div>
              ))
            ) : (
              <div key={v4()} timeout={500} className="item">
                <div className="card">
                  <h2>
                    No <span className="text-primary">Project</span> Tasks found
                  </h2>
                </div>
              </div>
            )}{" "}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default PartRequestList;
