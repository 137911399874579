import React, { useRef, Fragment } from 'react';
import StockItemResults from './searchPart/stockItemResults';
import StockItemQty from './searchPart/stockItemQty';
import AddNewStockItem from './searchPart/addNewStockItem';
import AddUnsvcStockItem from './searchPart/addUnsvcStockItem';
import PartRequestList from './PartsRequestList/partRequestList';
import { useStoreState, useStoreActions } from 'easy-peasy';

const PartsRequest = () => {
  const {
    stockItemSearchValue,
    showStockItemSearchResults,
    showStockItemQty,
    showAddNewStockItem,
    showAddUnsvcStockItem,
  } = useStoreState((state) => ({
    stockItemSearchValue: state.stockItemSearchValue,
    showStockItemSearchResults: state.showStockItemSearchResults,
    showStockItemQty: state.showStockItemQty,
    showAddNewStockItem: state.showAddNewStockItem,
    showAddUnsvcStockItem: state.showAddUnsvcStockItem,
  }));

  const {
    setLoading,
    clearStockItemSearchValue,
    setStockItemSearchValue,
    // filterStockItems,
    cleanupStockItemSearch,
    searchItems,
    setPartNotFoundInventoryID,
  } = useStoreActions((actions) => ({
    setLoading: actions.setLoading,
    clearStockItemSearchValue: actions.clearStockItemSearchValue,
    setStockItemSearchValue: actions.setStockItemSearchValue,
    // filterStockItems: actions.filterStockItems,
    cleanupStockItemSearch: actions.cleanupStockItemSearch,
    searchItems: actions.searchItems,
    setPartNotFoundInventoryID: actions.setPartNotFoundInventoryID,
  }));

  const text = useRef('');

  const onChange = (e) => {
    if (text.current.value !== '') {
      setStockItemSearchValue(e.target.value);
    } else {
      clearStockItemSearchValue();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (stockItemSearchValue.length !== 0) {
      setLoading(true);
      setPartNotFoundInventoryID();
      cleanupStockItemSearch();
      // filterStockItems(stockItemSearchValue.toString());
      searchItems(stockItemSearchValue.toString());
    } else return;
  };

  return (
    <Fragment>
      {showStockItemSearchResults ? (
        <StockItemResults />
      ) : showStockItemQty ? (
        <StockItemQty />
      ) : showAddNewStockItem ? (
        <AddNewStockItem />
      ) : showAddUnsvcStockItem ? (
        <AddUnsvcStockItem />
      ) : (
        <Fragment>
          <form onSubmit={onSubmit}>
            <div className="input-group mb-3">
              <input
                ref={text}
                onChange={onChange}
                type="text"
                className="form-control"
                placeholder="Search by part number"
                aria-label="Search by part number"
                aria-describedby="basic-addon2"
                value={stockItemSearchValue}
                autoFocus
                name="partSearchValue"
              />
              <div
                className="input-group-append"
                style={{ marginTop: '1.2rem' }}
                onClick={onSubmit}
              >
                <button
                  className="btn btn-success"
                  type="button"
                  disabled={stockItemSearchValue.length === 0}
                >
                  <i className="fa fa-search text-white mr-2"></i>
                  Search
                </button>
              </div>
            </div>
          </form>
          <PartRequestList />
        </Fragment>
      )}
    </Fragment>
  );
};

export default PartsRequest;
