import React, { Fragment } from 'react';
import OptionsFilter from './OptionsFilter';
import OptionsItem from './OptionsItem';
import Spinner from '../layout/Spinner';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStoreState } from 'easy-peasy';
import { v4 } from 'uuid';

const Options = () => {
  const {
    nonRoutineOptionType,
    nonRoutineOptions,
    filteredNonRoutineOptions,
    filteredNonRoutingSubOptions,
    loading,
  } = useStoreState((state) => ({
    nonRoutineOptionType: state.nonRoutineOptionType,
    nonRoutineOptions: state.nonRoutineOptions,
    filteredNonRoutineOptions: state.filteredNonRoutineOptions,
    filteredNonRoutingSubOptions: state.filteredNonRoutingSubOptions,
    loading: state.loading,
  }));

  return (
    <Fragment>
      <OptionsFilter />
      <div className="inner-scroll">
        {nonRoutineOptions !== null && !loading ? (
          <TransitionGroup>
            {nonRoutineOptions[nonRoutineOptionType].length > 0 ? (
              filteredNonRoutineOptions !== null ? (
                filteredNonRoutineOptions.map((o, index) => (
                  <CSSTransition key={index} timeout={500} classNames="item">
                    <OptionsItem option={o} />
                  </CSSTransition>
                ))
              ) : nonRoutineOptionType === 'chapters' ||
                nonRoutineOptionType === 'zones' ||
                nonRoutineOptionType === 'laborItems' ? (
                nonRoutineOptions[nonRoutineOptionType].map((o, index) => (
                  <CSSTransition key={index} timeout={500} classNames="item">
                    <OptionsItem option={o} />
                  </CSSTransition>
                ))
              ) : (
                nonRoutineOptionType === 'subChapters' &&
                filteredNonRoutingSubOptions.map((o, index) => (
                  <CSSTransition key={index} timeout={500} classNames="item">
                    <OptionsItem option={o} />
                  </CSSTransition>
                ))
              )
            ) : (
              <CSSTransition key={v4()} timeout={500} classNames="item">
                <div className="card">
                  <h2>
                    No <span className="text-primary">Options</span> found
                  </h2>
                </div>
              </CSSTransition>
            )}{' '}
          </TransitionGroup>
        ) : (
          <Spinner />
        )}
      </div>
    </Fragment>
  );
};

export default Options;
