import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import store from './store';
import { StoreProvider, createStore } from 'easy-peasy';

import Navbar from './components/layout/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Alerts from './components/layout/Alerts';
import SearchProjects from './components/pages/SearchProjects';
import Task from './components/pages/Task';
import Schedule from './components/pages/Schedule';
import NavbarBottom from './components/layout/NavbarBottom';

import './STSBootstrap.css';
import './App.css';
import Login from './components/pages/Login';
import PrivateRoute from './components/routing/PrivateRoute';
import SelectGeography from './components/pages/SelectGeography';

import InActiveTimerContainer from './components/pages/InActiveTimerContainer';

const initStore = createStore(store);

function App() {
  return (
    <StoreProvider store={initStore}>
      <InActiveTimerContainer />
      <Router>
        <div>
          <Fragment>
            <Navbar />
            <Alerts />
          </Fragment>
          <div className="container">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/geography" component={SelectGeography} />
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute exact path="/about" component={About} />
              <PrivateRoute
                exact
                path="/searchProjects"
                component={SearchProjects}
              />
              <PrivateRoute exact path="/task" component={Task} />
              <PrivateRoute exact path="/schedule" component={Schedule} />
            </Switch>
          </div>
          <NavbarBottom />
        </div>
      </Router>
    </StoreProvider>
  );
}

export default App;
