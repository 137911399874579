import React from 'react';
import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
//import moment from 'moment';

const OptionsItem = ({ option }) => {
  const { nonRoutineOptionType } = useStoreState((state) => ({
    nonRoutineOptionType: state.nonRoutineOptionType,
  }));

  const {
    setAtaChapterID,
    setAtaSubChapterID,
    setZoneID,
    setLaborItemID,
    setShowNonRoutineOptions,
    setFilteredNonRoutingSubOptions,
  } = useStoreActions((actions) => ({
    setAtaChapterID: actions.setAtaChapterID,
    setAtaSubChapterID: actions.setAtaSubChapterID,
    setZoneID: actions.setZoneID,
    setLaborItemID: actions.setLaborItemID,
    setShowNonRoutineOptions: actions.setShowNonRoutineOptions,
    setFilteredNonRoutingSubOptions: actions.setFilteredNonRoutingSubOptions,
  }));

  let id;
  let description;

  if (nonRoutineOptionType === 'chapters') {
    id = option.ChapterID;
    description = option.Description;
  } else if (nonRoutineOptionType === 'subChapters') {
    id = option.SubChapterID;
    description = option.Description;
  } else if (nonRoutineOptionType === 'zones') {
    id = option.ZoneID;
    description = option.Description;
  } else if (nonRoutineOptionType === 'laborItems') {
    id = option.InventoryID;
    description = option.Description;
  }

  // const { setAlerts, setLoading } = useStoreActions((actions) => ({
  //   setAlerts: actions.setAlerts,
  //   setLoading: actions.setLoading,
  // }));

  const onClick = (e) => {
    const filter = e.target.getAttribute('value');
    if (nonRoutineOptionType === 'chapters') {
      setAtaChapterID(filter);
      setFilteredNonRoutingSubOptions(filter);
    } else if (nonRoutineOptionType === 'subChapters') {
      setAtaSubChapterID(filter);
    } else if (nonRoutineOptionType === 'zones') {
      setZoneID(filter);
    } else if (nonRoutineOptionType === 'laborItems') {
      setLaborItemID(filter);
    }
    setShowNonRoutineOptions(false);
  };

  return (
    <div className="card-slim bg-light mt-1">
      <div className="options-list-grid-2" onClick={onClick} value={id}>
        <div className="text-primary" value={id}>
          {id}
        </div>
        <div className="text-success" value={id}>
          {description}
        </div>
      </div>
    </div>
  );
};

OptionsItem.propTypes = {
  option: PropTypes.object.isRequired,
};

export default OptionsItem;
