import React from "react";
import { useStoreState } from "easy-peasy";

const Alerts = () => {
  const { alerts, user, showCentrikButton } = useStoreState((state) => ({
    alerts: state.alerts,
    user: state.user,
    showCentrikButton: state.showCentrikButton,
  }));

  const makeError = (id, type, e) => {
    return (
      <span
        key={id + Math.floor(Math.random() * 10000 + 1).toString()}
        className={`alert alert-${type}`}
        style={{ border: "none", borderRadius: "0px" }}
      >
        <i className="fas fa-info-circle mb-2" /> {e}
        <br />
      </span>
    );
  };

  const openCentrik = () => {
    window.open(user.validities.data.centrikURL, "_blank");
  };

  const makeAlert = (a) => {
    const id = a.id;
    const type = a.type;
    return (
      <div
        key={a.id + Math.floor(Math.random() * 1000 + 1).toString()}
        className={`alert alert-${a.type}`}
      >
        {user && !user.mechanicQualified ? (
          <button
            type="button"
            className="centrik-url-button"
            onClick={openCentrik}
            hidden={!showCentrikButton}
          >
            <img
              src="https://kioskbranding.blob.core.windows.net/images/centrikLogo.png"
              alt=""
            />
          </button>
        ) : // </Link>
        null}
        {a.error.map((m) => makeError(id, type, m))}
      </div>
    );
  };

  return (
    alerts &&
    alerts.length > 0 &&
    alerts.map((alert) =>
      typeof alert.error === "string" ? (
        <div key={alert.id} className={`alert alert-${alert.type}`}>
          <i className="fas fa-info-circle" /> {alert.error}
          {user && !user.mechanicQualified ? (
            <a
              href={"https://stsaviation.centrik.net"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                className="centrik-url-button"
                hidden={showCentrikButton}
              >
                <img
                  src="https://kioskbranding.blob.core.windows.net/images/centrikLogo.png"
                  alt=""
                />
              </button>
            </a>
          ) : null}
        </div>
      ) : (
        makeAlert(alert)
      )
    )
  );
};

export default Alerts;
