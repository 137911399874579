import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStoreState, useStoreActions } from 'easy-peasy';
import createItemRequestPDF from '../../../reports/itemRequest';
import moment from 'moment';

//import { getBrandingImage } from '../../../services/cfbsKiosk';

const PartRequestListItem = ({ part }) => {
  const { user, currentTask, company, partRequestList } = useStoreState(
    (state) => ({
      user: state.user,
      currentTask: state.currentTask,
      company: state.user.CompanyAbrv,
      partRequestList: state.partRequestList,
    })
  );

  const { getReportImage, setLoading, setReportingItemReq, getPartReq } =
    useStoreActions((actions) => ({
      getReportImage: actions.getReportImage,
      setLoading: actions.setLoading,
      setReportingItemReq: actions.setReportingItemReq,
      getPartReq: actions.getPartReq,
    }));

  let customerCompanyWHSE = null;
  let customerWarehouse = null;
  let companyWarehouse = null;

  user.customerCompanyWHSE && (customerCompanyWHSE = user.customerCompanyWHSE);
  currentTask.CustomerWarehouse &&
    currentTask.CustomerWarehouse.value &&
    (customerWarehouse = currentTask.CustomerWarehouse.value);
  currentTask.CompanyWarehouse &&
    currentTask.CompanyWarehouse.value &&
    (companyWarehouse = currentTask.CompanyWarehouse.value);

  const {
    InventoryCD,
    Qty,
    Description,
    UsrPriorityLevel,
    UsrNeedBy,
    PartStatus,
    ItemReqNumber,
    RequestedBy,
    OrderNbr,
  } = part;

  const toTitleCase = (str) => {
    return (
      str.replace(/\//g, '').charAt(0).toUpperCase() +
      str.replace(/\//g, '').substr(1).toLowerCase()
    );
  };

  const defineClass = (value) => {
    let textClass = 'text-success';
    parseInt(value, 10) <= 0 && (textClass = 'text-danger');
    return textClass;
  };

  const getTime = (timeInput) => {
    const offset = new Date().getTimezoneOffset();

    const offsetCorrectedTime = moment(
      new Date(timeInput).toUTCString()
    ).subtract(offset, 'minutes');
    //return offsetCorrectedTime.format('dddd YYYY-MM-DD HH:mm a');
    return `${offsetCorrectedTime.format('l')} ${offsetCorrectedTime.format(
      'LT'
    )}`;
  };

  const buildItemReq = async (OrderNbr) => {
    const partRequestItem = partRequestList.filter(
      (p) => p.OrderNbr === OrderNbr
    );
    const reportingItemReq = {
      ItemReqID: partRequestItem[0].ItemReqID,
      OrderNbr: partRequestItem[0].OrderNbr,
      ContractCD: partRequestItem[0].ProjectID,
      TaskCD: partRequestItem[0].TaskID,
      InventoryCD: partRequestItem[0].InventoryCD,
      ItemDescr: partRequestItem[0].Description,
      ProjectDescription: currentTask.ProjectDescription.value,
      TaskDescription: currentTask.ProjectTaskDescription.value,
      CustEquipCD: partRequestItem[0].NNumber,
      NeedBy: partRequestItem[0].UsrNeedBy,
      EmployeeName: partRequestItem[0].RequestedBy,
      CustomerName: partRequestItem[0].CustomerName,
      CreatedAt: getTime(partRequestItem[0].CreatedAt),
      Zone: partRequestItem[0].Zone,
      UOM: partRequestItem[0].UOM,
      Qty: partRequestItem[0].Qty,
      Priority: partRequestItem[0].UsrPriorityLevel,
    };
    setReportingItemReq(reportingItemReq);
    return reportingItemReq;
  };

  const printItemRequest = async (itemReq) => {
    const reportingItemReq = await buildItemReq(itemReq);
    // const blob = !logoU8string
    //   ? await getReportImage()
    //   : new TextEncoder().encode(logoU8string);
    const blob = await getReportImage();

    //setTimeout(async () => {
    createItemRequestPDF(blob, reportingItemReq);
    //}, 1000);

    setLoading(false);
  };
  const clientWarehouse = toTitleCase(currentTask.Warehouse.value);

  const warehouseLookup = `Qty${clientWarehouse}`;

  // (clientQtyString = ` / ${clientWarehouse} (${parseInt(
  //     part[warehouseLookup]
  //   )})`)
  // : (clientQtyString = ` / No Client Stock`);

  const buildStandardOnHand = () => {
    return (
      <div className="ml-3 text-left ">
        On Hand: {company} (
        <span className={defineClass(part[`Qty${toTitleCase(company)}`])}>
          {parseInt(part[`Qty${toTitleCase(company)}`], 10) || 0}
        </span>
        {') / '}
        {part[warehouseLookup] ? (
          <Fragment>
            {clientWarehouse}
            {' ('}
            <span className={defineClass(part[warehouseLookup])}>
              {parseInt(part[warehouseLookup], 10)}
            </span>
            {')'}
          </Fragment>
        ) : (
          <span className="text-danger">No Client Stock</span>
        )}
        )
      </div>
    );
  };

  const buildCustomerCompanyWHSEOnHand = () => {
    return (
      <div className="ml-3 text-center ">
        On Hand: {customerWarehouse}
        {' ('}
        <span className={defineClass(part[`Qty${customerWarehouse}`])}>
          {parseInt(part[`Qty${customerWarehouse}`], 10) || 0}
        </span>
        {') / '}
        {companyWarehouse} (
        <span
          className={defineClass(part[`Qty${toTitleCase(companyWarehouse)}`])}
        >
          {parseInt(part[`Qty${toTitleCase(companyWarehouse)}`], 10) || 0}
        </span>
        {') / Total Available: ('}
        <span className={defineClass(part.TotalOnHand)}>
          {parseInt(part.TotalOnHand, 10) || 0}
        </span>
        {')'}
      </div>
    );
  };

  return (
    <div className="light card-slim bg-light my-3">
      <div className="grid-5 my-3">
        <div className="text-primary mt-3 ml-3 text-left">
          {InventoryCD} - {Description}
        </div>
        <div className="text-primary mt-3 ml-3 text-left">
          {UsrPriorityLevel}
        </div>
        <div className="text-primary mt-3 ml-3 text-left">{UsrNeedBy}</div>
        <div className="text-primary mt-3 ml-3 text-left">
          {parseInt(Qty, 10)}
        </div>
        <div className="text-primary mt-3 ml-3 text-left">{PartStatus}</div>
      </div>
      <div className="grid-3 text-dark">
        {!customerCompanyWHSE ? buildStandardOnHand() : <div></div>}

        <div>
          {OrderNbr ? (
            <i
              className="fa fa-print text-success medium"
              title="Print ticket"
              onClick={() => printItemRequest(ItemReqNumber)}
            ></i>
          ) : (
            <i
              className="fa text-warning medium"
              title="Check for ticket"
              onClick={() => getPartReq()}
            >
              &#xf021;
            </i>
          )}
        </div>
        <div className="mr-3 text-right ">
          Request: {ItemReqNumber}
          <br></br>
          {RequestedBy && `by: ${RequestedBy}`}
        </div>
      </div>
      {customerCompanyWHSE && buildCustomerCompanyWHSEOnHand()}
    </div>
  );
};

PartRequestListItem.propTypes = {
  part: PropTypes.object.isRequired,
};

export default PartRequestListItem;
