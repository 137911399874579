import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStoreActions, useStoreState } from 'easy-peasy';

const StockItemResultItem = ({ stockItem }) => {
  const { currentTask, company, user } = useStoreState((state) => ({
    currentTask: state.currentTask,
    company: state.user.CompanyAbrv,
    user: state.user,
  }));

  let customerCompanyWHSE = null;
  let customerWarehouse = null;
  let companyWarehouse = null;

  user.customerCompanyWHSE && (customerCompanyWHSE = user.customerCompanyWHSE);
  currentTask.CustomerWarehouse &&
    currentTask.CustomerWarehouse.value &&
    (customerWarehouse = currentTask.CustomerWarehouse.value);
  currentTask.CompanyWarehouse &&
    currentTask.CompanyWarehouse.value &&
    (companyWarehouse = currentTask.CompanyWarehouse.value);

  const customLabels = user && user.customLabels ? user.customLabels : null;

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const defineClass = (value) => {
    let textClass = 'text-success';
    parseInt(value, 10) <= 0 && (textClass = 'text-danger');
    return textClass;
  };

  const clientWarehouse = toTitleCase(currentTask.Warehouse.value);

  const warehouseLookup = `Qty${clientWarehouse}`;

  const {
    setStockItemToAdd,
    setShowStockItemSearchResults,
    cleanupStockItemSearchResultsModal,
    setShowStockItemQty,
    updateUnservicable,
    setShowUnservicablePopup,
  } = useStoreActions((actions) => ({
    setStockItemToAdd: actions.setStockItemToAdd,
    setShowStockItemSearchResults: actions.setShowStockItemSearchResults,
    cleanupStockItemSearchResultsModal:
      actions.cleanupStockItemSearchResultsModal,
    setShowStockItemQty: actions.setShowStockItemQty,
    updateUnservicable: actions.updateUnservicable,
    setShowUnservicablePopup: actions.setShowUnservicablePopup,
  }));

  const addToStockItemRequest = (e) => {
    setStockItemToAdd(stockItem);
    setShowStockItemQty(true);
    toggle();
  };

  const toggle = () => {
    setShowStockItemSearchResults(false);
    cleanupStockItemSearchResultsModal();
  };

  const onShowUnserviceablePartForm = () => {
    const { InventoryCD, InventoryID, Description } = stockItem;
    updateUnservicable({
      PartNbr: {
        InventoryCD,
        InventoryID,
      },
      Description,
    });
    setShowUnservicablePopup(true);
  };

  const buildStandardOnHand = () => {
    return (
      <span className="text-primary text-left project-description">
        {stockItem.Description} ({stockItem.BaseUnit})
        <br />
        <span className="ml-3 text-dark">
          On Hand: {company} (
          <span
            className={defineClass(stockItem[`Qty${toTitleCase(company)}`])}
          >
            {parseInt(stockItem[`Qty${toTitleCase(company)}`], 10)}
          </span>
          {') / '}
          {stockItem[warehouseLookup] ? (
            <Fragment>
              {clientWarehouse}
              {' ('}
              <span className={defineClass(stockItem[warehouseLookup])}>
                {parseInt(stockItem[warehouseLookup], 10)}
              </span>
              {')'}
            </Fragment>
          ) : (
            <span className="text-danger">No Client Stock</span>
          )}
          )
        </span>
      </span>
    );
  };

  const buildCustomerCompanyWHSEOnHand = () => {
    return (
      <span className="text-primary text-left project-description">
        {stockItem.Description.trim()} ({stockItem.BaseUnit})
        <br />
        <span className="ml-3 text-dark">
          On Hand:
          {customerWarehouse}
          {' ('}
          <span
            className={defineClass(
              stockItem[`Qty${toTitleCase(customerWarehouse)}`]
            )}
          >
            {parseInt(stockItem[`Qty${toTitleCase(customerWarehouse)}`], 10) ||
              0}
          </span>
          {')'}
          <br></br>
          <span className="ml-4">
            {companyWarehouse}
            <span
              className={defineClass(
                stockItem[`Qty${toTitleCase(companyWarehouse)}`]
              )}
            >
              {' ('}
              {parseInt(stockItem[`Qty${toTitleCase(companyWarehouse)}`], 10) ||
                0}
            </span>

            {')'}
          </span>
          <br></br>
          <span className="ml-4">
            {'Total Available: ('}
            <span className={defineClass(stockItem.TotalOnHand)}>
              {stockItem.TotalOnHand}
            </span>

            {')'}
          </span>
        </span>
      </span>
    );
  };

  return (
    <Fragment>
      <li className="results-list">
        <div
          className={
            'mb-2 ' + (currentTask.unsvcParts ? 'grid-3-btn' : 'grid-1-btn')
          }
        >
          <div className="text-success mt-2 ml-2 text-left">
            {stockItem.InventoryCD.trim()}
            <span className="text-dark">{' - '}</span>
            {customerCompanyWHSE
              ? buildCustomerCompanyWHSEOnHand()
              : buildStandardOnHand()}
            {/* <span className="text-primary text-left project-description">
              {stockItem.Description} ({stockItem.BaseUnit})
              <br />
              <span className="ml-3 text-dark">
                On Hand: {company} (
                <span
                  className={defineClass(
                    stockItem[`Qty${toTitleCase(company)}`]
                  )}
                >
                  {parseInt(stockItem[`Qty${toTitleCase(company)}`], 10)}
                </span>
                {') / '}
                {stockItem[warehouseLookup] ? (
                  <Fragment>
                    {clientWarehouse}
                    {' ('}
                    <span className={defineClass(stockItem[warehouseLookup])}>
                      {parseInt(stockItem[warehouseLookup], 10)}
                    </span>
                    {')'}
                  </Fragment>
                ) : (
                  <span className="text-danger">No Client Stock</span>
                )}
              </span>
            </span> */}
          </div>
          <button
            className="btn btn-warning"
            onClick={addToStockItemRequest}
            value={stockItem.InventoryID}
          >
            Add To Request
          </button>
          {currentTask.unsvcParts && (
            <button
              className="btn btn-primary"
              onClick={onShowUnserviceablePartForm}
            >
              {customLabels &&
              customLabels.unserviceablePart &&
              customLabels.unserviceablePart.Title
                ? 'Add To ' + customLabels.unserviceablePart.Title + ' Request'
                : 'Add To Unserviceable Part Request'}
            </button>
          )}
        </div>
      </li>
    </Fragment>
  );
};

StockItemResultItem.propTypes = {
  stockItem: PropTypes.object.isRequired,
};

export default StockItemResultItem;
