import React from 'react';
import { Row, Form, Col, Button, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStoreActions, useStoreState } from 'easy-peasy';
import createUnserviceableCard from '../../reports/unserviceable';
import moment from 'moment';

// use 235953 for badge id

const useFields = () => {
  const { unservicable, user } = useStoreState((state) => state);
  const { updateUnservicable } = useStoreActions((actions) => actions);

  const customLabels =
    user && user.customLabels && user.customLabels.unserviceablePart
      ? user.customLabels.unserviceablePart
      : null;

  return [
    {
      label: 'Part Nbr',
      input: {
        readOnly: true,
        name: 'partNbr',
        id: 'partNbr',
        placeholder: 'Enter part number',
        value: unservicable.PartNbr.InventoryCD,
      },
    },
    {
      label: 'Serial Nbr',
      input: {
        name: 'serialNbr',
        id: 'serialNbr',
        placeholder: 'Enter serial number',
        onChange: (evt) => updateUnservicable({ SerialNbr: evt.target.value }),
        value: unservicable.SerialNbr,
      },
    },
    {
      label: 'Mod State/Shelf Life',
      input: {
        name: 'stateShelfLife',
        id: 'stateShelfLife',
        placeholder: 'Enter mod state/shelf life',
        onChange: (evt) =>
          updateUnservicable({ StateShelfLife: evt.target.value }),
        value: unservicable.StateShelfLife,
      },
    },
    {
      label: 'Position',
      input: {
        name: 'position',
        id: 'position',
        placeholder: 'Enter position',
        onChange: (evt) => updateUnservicable({ Position: evt.target.value }),
        value: unservicable.Position,
      },
    },
    {
      label: 'Quantity',
      input: {
        name: 'quantity',
        id: 'quantity',
        placeholder: 'Enter quantity',
        onChange: (evt) =>
          updateUnservicable({ Quantity: Number(evt.target.value) }),
        value: unservicable.Quantity,
        type: 'number',
      },
    },
    {
      label: 'Date',
      input: {
        name: 'date',
        id: 'date',
        placeholder: 'MM/DD/YYYY',
        onChange: (evt) => updateUnservicable({ Date: evt.target.value }),
        value: unservicable.Date,
        type: 'date',
        onKeyDown: (event) => event.preventDefault(),
      },
    },
    {
      label:
        customLabels && customLabels.Station ? customLabels.Station : 'Station',
      input: {
        name: 'station',
        id: 'station',
        placeholder: 'Enter station',
        onChange: (evt) => updateUnservicable({ Station: evt.target.value }),
        value: unservicable.Station,
      },
    },
    {
      label: 'Owner',
      input: {
        name: 'owner',
        id: 'owner',
        placeholder: 'Enter owner',
        onChange: (evt) => updateUnservicable({ Owner: evt.target.value }),
        value: unservicable.Owner,
      },
    },
    {
      label: 'Tech Log Ref',
      input: {
        name: 'techLogRef',
        id: 'techLogRef',
        placeholder: 'Enter tech log ref',
        onChange: (evt) => updateUnservicable({ TechLogRef: evt.target.value }),
        value: unservicable.TechLogRef,
      },
    },
    {
      label: 'Operator',
      input: {
        name: 'operator',
        id: 'operator',
        placeholder: 'Enter operator',
        onChange: (evt) => updateUnservicable({ Operator: evt.target.value }),
        value: unservicable.Operator,
      },
    },
    {
      label: 'Description',
      input: {
        name: 'description',
        id: 'description',
        placeholder: 'Enter description',
        onChange: (evt) =>
          updateUnservicable({ Description: evt.target.value }),
        value: unservicable.Description,
      },
      col: { md: 12 },
    },
    {
      label: 'Reason For Removal',
      input: {
        name: 'removalReason',
        id: 'removalReason',
        placeholder: 'Enter reason for removal',
        onChange: (evt) =>
          updateUnservicable({ ReasonforRemoval: evt.target.value }),
        value: unservicable.ReasonforRemoval,
        as: 'textarea',
      },
      col: { md: 12 },
    },
  ];
};

const useButtons = () => {
  const {
    removeUnservicablePart,
    setLoading,
    setAlerts,
    updateUnservicable,
    setShowUnservicablePopup,
    setShowStockItemSearchResults,
    getReportImage,
  } = useStoreActions((actions) => actions);

  const { user, unservicable, currentTask } = useStoreState((state) => state);

  const hiddenFields =
    user && user.hiddenFields && user.hiddenFields.unserviceablePart
      ? user.hiddenFields.unserviceablePart
      : null;

  const onSubmit = async () => {
    setLoading(true);

    if (currentTask.printNonRoutineTaskCard) {
      const companyLogoBlob = await getReportImage();
      createUnserviceableCard(companyLogoBlob, {
        ...unservicable,
        ...currentTask,
        PartNbr: unservicable.PartNbr.InventoryCD,
        Inspector: user.Inspector === 'YES' ? user.LegalName : '',
        EquipID: currentTask.EquipID.value,
        Position: unservicable.Position,
        Date: moment(unservicable.Date).format('L'),
      });
    }

    removeUnservicablePart({
      ...unservicable,
      PartNbr: unservicable.PartNbr.InventoryID,
      ReasonforRemoval: `${unservicable.ReasonforRemoval} - Position: ${unservicable.Position}`,
      Inspector: user.AccountID,
      Warehouse: currentTask.WarehouseID.value,
      Location: currentTask.LocationID.value,
      ProjectID: currentTask.WorkOrderID.value,
      Task: currentTask.ProjectTaskID.value,
      QuoteNbr: currentTask.ProjectTaskID.value,
    })
      .then(({ data }) => {
        console.log('SUCCESS', data);
        setLoading(false);

        setAlerts({
          error: `Unserviceable Part Request: ${data.QuoteNbr.value} created successfully`,
          type: 'success',
        });
        updateUnservicable({
          SerialNbr: '',
          StateShelfLife: '',
          Description: '',
          Quantity: 0,
          Date: '',
          Station: '',
          Owner: '',
          TechLogRef: '',
          Operator: '',
          ReasonforRemoval: '',
        });
        setShowUnservicablePopup(false);
        setShowStockItemSearchResults(false);
      })
      .catch((error) => {
        console.log('ERROR', error.response);
        setLoading(false);
        setAlerts({
          error: `Submission failed.`,
          type: 'danger',
        });
      });
  };

  return [
    {
      variant: 'light',
      text: 'Cancel',
      onClick: () => setShowUnservicablePopup(false),
      id: 'cancel-btn',
    },
    {
      variant: 'success',
      text: 'Submit',
      onClick: () => onSubmit(),
      id: 'submit-btn',
      disabled:
        unservicable.Quantity <= 0 ||
        unservicable.SerialNbr.trim() === '' ||
        unservicable.StateShelfLife.trim() === '' ||
        unservicable.Description.trim() === '' ||
        unservicable.Date.trim() === '' ||
        unservicable.Station.trim() === '' ||
        ((!hiddenFields || (hiddenFields && !hiddenFields.includes('Owner'))) &&
          unservicable.Owner.trim() === '') ||
        unservicable.TechLogRef.trim() === '' ||
        ((!hiddenFields ||
          (hiddenFields && !hiddenFields.includes('Operator'))) &&
          unservicable.Operator.trim() === '') ||
        unservicable.ReasonforRemoval.trim() === '',
    },
  ];
};

const UnservicablePart = () => {
  const { user, nonRoutineOptions, unservicable } = useStoreState(
    (state) => state
  );
  const { updateUnservicable } = useStoreActions((actions) => actions);

  const customLabels =
    user && user.customLabels && user.customLabels.unserviceablePart
      ? user.customLabels.unserviceablePart
      : null;

  const hiddenFields =
    user && user.hiddenFields && user.hiddenFields.unserviceablePart
      ? user.hiddenFields.unserviceablePart
      : null;

  const itemConditions =
    nonRoutineOptions && nonRoutineOptions.itemConditions
      ? nonRoutineOptions.itemConditions
      : null;

  const fields = useFields();
  const buttons = useButtons();

  return (
    <Form style={{ padding: '30px 0' }}>
      <h1 className="text-center">
        {customLabels && customLabels.Title
          ? customLabels.Title
          : 'Unserviceable Part'}
      </h1>
      <br />
      <Row>
        {fields.map(({ label, input, col = { md: 6 }, ...props }) => {
          if (
            !hiddenFields ||
            (hiddenFields && !hiddenFields.includes(label))
          ) {
            if (label === 'Mod State/Shelf Life' && itemConditions) {
              return (
                <Col
                  className="mb-3"
                  sm={12}
                  key={input.id}
                  {...col}
                  {...props}
                >
                  <Form.Group className="text-left">
                    <Form.Label>{label}</Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="warning"
                        id="dropdown-basic"
                        className="btn-block"
                      >
                        {unservicable.StateShelfLife
                          ? unservicable.StateShelfLife
                          : 'Mode State/Shelf Life'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {itemConditions.map((c, i) => {
                          return (
                            <Dropdown.Item
                              value={c.ConditionID}
                              key={c.ConditionID}
                              onClick={() =>
                                updateUnservicable({
                                  StateShelfLife: c.ConditionID,
                                })
                              }
                            >
                              {c.ConditionDescription}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>
              );
            } else {
              return (
                <Col
                  className="mb-3"
                  sm={12}
                  key={input.id}
                  {...col}
                  {...props}
                >
                  <Form.Group className="text-left">
                    <Form.Label>{label}</Form.Label>
                    <Form.Control required {...input} />
                  </Form.Group>
                </Col>
              );
            }
          } else {
            return '';
          }
        })}
      </Row>

      <div style={{ margin: '20px 0' }} />

      <Row>
        {buttons.map(({ text, id, ...rest }) => (
          <Col sm={12} md={6} key={id} style={{ padding: '0 15px' }}>
            <Button {...rest} block>
              {text}
            </Button>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default UnservicablePart;
