import { centerText } from "../utilities/document";

export const X_START = 50;

export const showFillInBlank = (doc, { label, value, lineWidth }) => {
  doc.text(label);

  // show line
  const lineY = doc.y + 7;
  doc.moveTo(doc.x + 5, lineY);
  const lineXStart = doc.x;
  doc.lineTo(doc.x + lineWidth, lineY).stroke();

  // fill in blank
  centerText(doc, `${value || ""}`, lineWidth, lineXStart, doc.y - 2, {
    bold: true,
  });

  // move cursor for next fill in blank in the same row
  doc.x = lineXStart + lineWidth + 4;
};

export const showFillInBlankTable = (doc, rows) => {
  rows.forEach((fields) => {
    doc.x = X_START;
    doc.y += 25;
    fields.forEach((field) => showFillInBlank(doc, field));
  });
};
