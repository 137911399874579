import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';

const NavbarBottom = () => {
  const {
    user,
    page,
    projectTasks,
    barCode,
    usingCentrik,
    mechanicQualified,
    taskLimit,
    queueLocked,
  } = useStoreState((state) => ({
    page: state.page,
    user: state.user,
    barCode: state.barCode,
    projectTasks: state.projectTasks,
    usingCentrik: state.usingCentrik,
    mechanicQualified: state.qualifications.mechanicQualified,
    taskLimit: state.taskLimit,
    queueLocked: state.queueLocked,
  }));

  const {
    setAlerts,
    setPage,
    signOut,
    addTaskToProjectTasksByCode,
    cleanupSearch,
    setLoading,
    removeTasksFromProjectTasks,
    setBarCode,
    setCorrectiveAction,
    setForcedLogoutModal,
    // getTaskCardPDF,
  } = useStoreActions((actions) => ({
    setAlerts: actions.setAlerts,
    setPage: actions.setPage,
    signOut: actions.signOut,
    addTaskToProjectTasksByCode: actions.addTaskToProjectTasksByCode,
    cleanupSearch: actions.cleanupSearch,
    removeTasksFromProjectTasks: actions.removeTasksFromProjectTasks,
    setLoading: actions.setLoading,
    setBarCode: actions.setBarCode,
    setCorrectiveAction: actions.setCorrectiveAction,
    setForcedLogoutModal: actions.setForcedLogoutModal,
    // getTaskCardPDF: actions.getTaskCardPDF,
  }));

  const searchTasks = () => {
    user && user.validities
      ? setPage('search')
      : setAlerts({
          error:
            'Please wait until you name is green.  Your qualifications are still loading . . .',
          type: 'warning',
        });
  };

  const goHome = () => {
    cleanupSearch();
    setPage('home');
  };

  const scanTask = () => {
    if (user && user.validities) {
      const scannedValue = document.querySelector(
        'input[name="barCode"]'
      ).value;
      if (scannedValue) {
        if (queueLocked) {
          setForcedLogoutModal(true);
        } else {
          addTaskToProjectTasksByCode(scannedValue);
          //getTask(scannedValue);
          // setLoading(true);
          // getEmployee(scannedValue);
          setLoading(true);
        }
      } else {
        setAlerts({ error: 'No barcode value was detected', type: 'warning' });
      }
    } else {
      setAlerts({
        error:
          'Please wait until you name is green.  Your qualifications are still loading . . .',
        type: 'warning',
      });
    }
  };

  const notQualified = () => {
    setAlerts({
      error:
        'Your mechanical qualifications do not meet the minimum requirements to continue',
      type: 'warning',
    });
  };

  const setBarcode = (e) => {
    setBarCode(e.target.value);
  };

  // const markAllComplete = () => {
  //   setLoading(true);
  //   const projectsToRemove = projectTasks.map((p) => p.ProjectTaskID.value);
  //   removeTasksFromProjectTasks({
  //     projectTaskIds: projectsToRemove,
  //     status: 'complete',
  //   });
  //   // setAlerts({error: 'MARK ALL COMPLETE function selected',type: 'warning',});
  // };

  const stopAllWork = () => {
    setLoading(true);

    const project = {};
    project.remove = true;
    project.process = 'incompleteAll';

    setCorrectiveAction('');

    removeTasksFromProjectTasks(project);

    //setAlerts({ error: 'STOP ALL WORK function selected', type: 'warning' });
  };

  const exitApplication = () => {
    signOut();
  };

  if (user) {
    return (
      <div className="navbar-bottom fixed-bottom navbar-light">
        <div
          id="navBarBottom"
          className="grid-5 large"
          style={{ width: '100%' }}
        >
          <div>
            {page === 'home' ? (
              <Fragment>
                <Link
                  to="/searchProjects"
                  onClick={
                    mechanicQualified || !usingCentrik
                      ? searchTasks
                      : notQualified
                  }
                  hidden={taskLimit}
                >
                  <i className="fa fa-search text-primary"></i>
                </Link>

                <div
                  className="icon-description text-center"
                  hidden={taskLimit}
                >
                  Search Tasks
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <Link to="/" onClick={goHome}>
                  <i className="fas fa-tasks text-primary"></i>
                </Link>
                <div className="icon-description text-center">My Task List</div>
              </Fragment>
            )}
          </div>
          <div>
            {page === 'home' ? (
              <Fragment>
                {taskLimit ? (
                  <span
                    className="text-danger medium"
                    title={`Clock out of task ${projectTasks[0].ProjectTaskID.value}\nin order to clock into a new task`}
                  >
                    Open Task Limit Reached
                  </span>
                ) : (
                  <input
                    className="text-center "
                    type="text"
                    name="barCode"
                    value={barCode}
                    placeholder="scan card"
                    autoFocus
                    disabled={taskLimit}
                    onChange={
                      mechanicQualified || !usingCentrik
                        ? setBarcode
                        : notQualified
                    }
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        scanTask();
                      }
                    }}
                    autoComplete="off"
                  />
                )}
              </Fragment>
            ) : (
              ''
            )}
          </div>
          <div>{page === 'home' ? <Fragment /> : ''}</div>
          <div>
            {page === 'home' ? (
              <Fragment>
                <i
                  className="far fa-hand-paper text-danger"
                  onClick={
                    mechanicQualified || !usingCentrik
                      ? stopAllWork
                      : notQualified
                  }
                ></i>
                <div className="icon-description text-center">
                  Stop All Work
                </div>
              </Fragment>
            ) : (
              ''
            )}
          </div>
          <div>
            <i
              className="fas fa-sign-in-alt text-danger"
              onClick={exitApplication}
            ></i>
            <div className="icon-description text-center">Sign Out</div>
          </div>
        </div>
      </div>
    );
  } else {
    return '';
  }
};

export default NavbarBottom;
