import PDFDocument from '@react-pdf/pdfkit';
import blobStream from 'blob-stream';

import {
  makeNewPage,
  makeSection,
  DESCRIPTION_FONT_SIZE,
  makeSubsection,
  makeSubsectionHeading,
  makeLine,
} from '../reports/utilities/document';

const createItemRequestPDF = (blob, itemReq) => {
  //const logoUrl = require(`https://kioskbranding.blob.core.windows.net/images/${logo}`);

  try {
    // setup/testing data
    // const itemReq = {
    //   ItemReqID: '18119',
    //   OrderNbr: '014259',
    //   ContractCD: 'W001261',
    //   TaskCD: 'W001261 N0128',
    //   InventoryCD: 'NAS1149D0363K',
    //   ItemDescr: 'WASHER',
    //   TaskDescription:
    //     'AFT BAGGAGE DOOR FWD SLIDING TRACK HAS DENT AND GOUGE FS.1000.00 WL 97.50  CAMP# 22789329',
    //   CustEquipCD: 'N724EV',
    //   NeedBy: '08/11/2021',
    //   EmployeeName: 'Jiminez, Rodolfo',
    //   CustomerName: 'SKYWEST AIRLINES',
    //   CreatedAt: '08/11/2021  4:14 AM',
    //   Zone: '',
    //   UOM: 'EA',
    //   Qty: '1',
    //   Priority: 'AOG',
    // };

    const {
      OrderNbr,
      ContractCD,
      TaskCD,
      InventoryCD,
      ItemDescr,
      ProjectDescription,
      TaskDescription,
      CustEquipCD,
      NeedBy,
      EmployeeName,
      CustomerName,
      Zone,
      UOM,
      Qty,
      Priority,
    } = itemReq;

    const doc = new PDFDocument({ autoFirstPage: false, layout: 'landscape' });

    const stream = doc.pipe(blobStream());

    const page = makeNewPage(doc, 1, itemReq, blob);
    page.fontSize(DESCRIPTION_FONT_SIZE);
    //makeSubsection(page, CustEquipCD, 595, 125, 300);

    makeSection(page, `Item Request: ${OrderNbr}`, 15, 85);
    makeSection(page, `Customer: ${CustomerName}`, 145, 85);
    makeSection(page, `Requested By: ${EmployeeName}`, 400, 85);
    makeSection(page, `Date: ${NeedBy}`, 640, 85);

    makeLine(page, 3, [15, 96], [770, 96]);

    page.fontSize(DESCRIPTION_FONT_SIZE);

    //   page.moveDown();

    makeSubsectionHeading(page, 'Work Order', 15, 110, 80);
    makeSubsectionHeading(page, 'Description', 95, 110, 500);
    makeSubsectionHeading(page, 'Aircraft Registration Number', 595, 110, 300);

    makeLine(page, 1, [15, 121], [770, 121]);

    //   page.moveDown();

    makeSubsection(page, ContractCD, 15, 125, 80);
    makeSubsection(page, ProjectDescription, 95, 125, 500);
    makeSubsection(page, CustEquipCD, 595, 125, 300);

    //   page.moveDown();

    makeSubsectionHeading(page, 'Task', 15, 160, 50);
    makeSubsectionHeading(page, 'Task Description', 65, 160, 250);
    makeSubsectionHeading(page, 'Zone', 315, 160, 20);
    makeSubsectionHeading(page, 'Inventory ID', 435, 160, 50);
    makeSubsectionHeading(page, 'Item Description', 485, 160, 100);
    makeSubsectionHeading(page, 'Need By Date', 620, 160, 50);
    makeSubsectionHeading(page, 'UOM', 670, 160, 30);
    makeSubsectionHeading(page, 'Qty', 700, 160, 30);
    makeSubsectionHeading(page, 'Priority', 730, 160, 50);

    makeLine(page, 1, [15, 171], [770, 171]);

    //   page.moveDown();

    makeSubsection(page, TaskCD, 15, 175, 50);
    makeSubsection(page, TaskDescription, 65, 175, 250);
    makeSubsection(page, Zone, 315, 175, 20);
    makeSubsection(page, InventoryCD, 435, 175, 50);
    makeSubsection(page, ItemDescr, 485, 175, 100);
    makeSubsection(page, NeedBy, 620, 175, 50);
    makeSubsection(page, UOM, 670, 175, 30);
    makeSubsection(page, parseFloat(Qty, 2).toString(), 700, 175, 30);
    makeSubsection(page, Priority, 730, 175, 30);

    makeSubsection(page, 'WOREQ1', 15, 500, 100);
    makeSubsection(page, 'CRS# FAA  CRS# 1E1R898B', 15, 510, 100);

    doc.end();

    stream.on('finish', function () {
      // get a blob you can do whatever you like with
      // const blob = stream.toBlob('application/pdf');
      // or get a blob URL for display in the browser
      const url = stream.toBlobURL('application/pdf');
      window.open(url, '_blank');
    });

    //   res.setHeader(
    //     'Content-Disposition',
    //     `attachment; filename=PartsReq${OrderNbr}.pdf`
    //   );
    //   res.setHeader('Content-type', 'application/pdf');
    //   //res.status(200).send(doc);
    //   doc.pipe(res);
  } catch (err) {
    console.log(err);
  }
};
export default createItemRequestPDF;
